<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div
    class="
      min-h-full
      flex
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="@/assets/dre-ip_logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <!-- this action needs to change to an actual API call -->
      <form class="mt-8 space-y-6" @submit.prevent="login">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="phone-number" class="sr-only">Phone Number</label>
            <input
              id="phone-number"
              name="tel"
              type="tel"
              autocomplete="tel"
              required=""
              pattern="^[\+]?[0-9]{2}[\s]?[0-9]{4}[0-9]{4,6}[\s]*$"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-yellow-500
                focus:border-yellow-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Telephone number: +911234567890"
              v-model="voter_phone_number"
            />
          </div>
        </div>
        <vue-recaptcha
          sitekey="6LcyZ08hAAAAAEJeX_wRvT4JtNFrqQgXb6Wq3dpz"
          size="normal"
          theme="light"
          hl="en"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>

        <div class="form-check">
          <input
            class="
              form-check-input
              appearance-none
              h-4
              w-4
              border border-gray-300
              rounded-sm
              bg-white
              checked:bg-blue-600 checked:border-blue-600
              focus:outline-none
              transition
              duration-200
              mt-1
              align-top
              bg-no-repeat bg-center bg-contain
              float-left
              cursor-pointer
            "
            type="checkbox"
            value=""
            id="flexCheckDefault"
            v-model="checkbox"
          />
          <label class="form-check-label text-gray-800" for="flexCheckDefault">
            I hereby agree to follow all applicable T&C
          </label>
        </div>

        <div>
          <button
            v-if="
              this.voter_phone_number && this.checkbox && this.recaptchaToken
            "
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              disabled:bg-
              bg-yellow-600
              hover:bg-yellow-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-yellow-500
            "
          >
            Sign in
          </button>
          <button
            v-else
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              disabled:bg-
              bg-yellow-600
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-yellow-500
              opacity-50
              cursor-not-allowed
            "
            disabled
          >
            Sign in
          </button>
          <GDialog max-width="500" v-model="hasError">
            <div style="color: #000">
              <div style="padding: 20px">
                <h1
                  class="
                    text-base text-indigo-600
                    font-semibold
                    tracking-wide
                    uppercase
                    pt-2
                  "
                >
                  {{ errorTitle }}
                </h1>

                <h3 class="text-base tracking-wide pt-2 pb-2">
                  <p>
                    {{ errorDescription }}
                  </p>
                </h3>
              </div>
            </div>
          </GDialog>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import vueRecaptcha from "vue3-recaptcha2";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "LoginForm",

  components: {
    vueRecaptcha,
    GDialog,
  },

  data() {
    return {
      voter_phone_number: "+91 ",
      recaptchaToken: "",
      hasError: false,
      errorTitle: "",
      errorDescription: "",
      checkbox: false,
    };
  },
  methods: {
    login: function () {
      const data = {
        sms: this.voter_phone_number.replace(/\s+/g, ""),
        g_recaptcha_response: this.recaptchaToken,
      };

      axios({
        method: "post",
        url: "/api/auth/voter/challenge",
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then(() => this.$router.replace("/otp"))
        .catch((err) => {
          if (err.response.status == 422) {
            this.errorTitle = "Invalid phone number";
            this.errorDescription =
              "Please ensure your phone number uses the given format.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    recaptchaVerified(response) {
      this.recaptchaToken = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.recaptchaToken = "";
    },
    recaptchaFailed() {
      this.recaptchaToken = "";
    },
  },
});
</script>
