<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <!-- Election - dopdown for editing data -->
  <div>
    <p
      class="
        mt-2
        text-lg
        leading-8
        pb-2
        font-extrabold
        tracking-tight
        text-gray-900
        sm:text-1xl
      "
    >
      Edit Election Data
    </p>

    <!-- Election Name -->
    <div class="flex mb-3">
      <p class="leading-8 p-2 font-medium tracking-tight text-gray-900">
        Name:
      </p>
      <input
        type="text"
        class="
          appearance-none
          relative
          block
          w-full
          px-3
          py-2
          border border-gray-300
          placeholder-gray-500
          text-gray-900
          rounded-md
          focus:outline-none focus:z-10
          sm:text-sm
        "
        name="name"
        v-model="election.name"
        placeholder="Change the Name"
      />
    </div>

    <!-- Start Time -->
    <div class="flex mb-3">
      <p class="leading-8 p-2 font-medium tracking-tight text-gray-900">
        Election Times:
      </p>
      <Datepicker
        v-model="date"
        placeholder="Enter start and end dates"
        :minDate="new Date()"
        range
      />
      <select
        v-model="timeZoneOption"
        class="
          border border-gray-300
          text-gray-900 text-sm
          rounded-b-lg
          focus:ring-yellow-500 focus:border-yellow-500
          block
          w-full
          p-2.5
          dark:bg-gray-700
          dark:text-white
          dark:focus:ring-yellow-500
          dark:focus:border-yellow-500
        "
      >
        <option :key="timezone.value" v-for="timezone in loadTimeZoneList()">
          {{ timezone.textContent }}
        </option>
      </select>
    </div>

    <p class="leading-8 p-2 text-left font-medium tracking-tight text-gray-900">
      Electorates:
    </p>

    <!-- TODO: connect functions with store -->
    <div :key="electorate.id" v-for="electorate in election.electorates">
      <ShowElectionsElectorate
        :electorate="electorate"
        @update-group="updateGroup"
        @delete-electorate="deleteElectorate"
      />
    </div>

    <div>
      <form @submit.prevent="addNewElectorate">
        <div class="form-control">
          <input
            type="text"
            class="
              appearance-none
              rounded-none
              relative
              block
              w-full
              px-3
              py-2
              border border-gray-300
              placeholder-gray-500
              text-gray-900
              rounded-t-md
              focus:outline-none
              focus:ring-yellow-500
              focus:border-yellow-500
              focus:z-10
              sm:text-sm
            "
            name="electorateName"
            v-model="newElectorate"
            placeholder="Add new Electorate"
          />
        </div>
        <div class="flex px-3 py-4">
          <label class="flex items-center">
            <input type="checkbox" name="options" v-model="mutexElectorate" />
            <span class="ml-2 text-sm">Single Select Electorate</span>
          </label>
        </div>
        <input
          type="submit"
          value="Add Electorate"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-b-lg
            text-white
            bg-yellow-600
            hover:bg-yellow-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-yellow-500
          "
        />
      </form>
    </div>

    <!-- TODO: v-if questions is not empty -->
    <p class="leading-8 p-2 text-left font-medium tracking-tight text-gray-900">
      Questions:
    </p>

    <!-- TODO: connect functions with store -->
    <div :key="index" v-for="(question, index) in this.election.questions">
      <ShowElectionsQuestion
        :question="question"
        :electionID="election.id"
        :questionIndex="index"
        @update-candidates="updateCandidates"
        @delete-question="deleteQuestion"
        @update-group-constraints="updateGroupConstraints"
      />
    </div>
    <div>
      <!-- TODO: create method to add question -->
      <form @submit.prevent="addNewQuestion">
        <div class="form-control">
          <!-- <label>Question Name</label> -->
          <input
            type="text"
            class="
              appearance-none
              rounded-none
              relative
              block
              w-full
              px-3
              py-b-2
              border border-gray-300
              placeholder-gray-500
              text-gray-900
              rounded-t-md
              focus:outline-none
              focus:ring-yellow-500
              focus:border-yellow-500
              focus:z-10
              sm:text-sm
            "
            name="questionName"
            v-model="newQuestion"
            placeholder="Add new question"
          />
        </div>
        <input
          type="submit"
          value="Add Question"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-b-lg
            text-white
            bg-yellow-600
            hover:bg-yellow-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-yellow-500
          "
        />
      </form>
    </div>

    <!-- Checkbox for publishing the election -->

    <div class="flex px-3 py-4">
      <label class="flex items-center">
        <input type="checkbox" name="options" v-model="publishElection" />
        <span class="ml-2 text-sm">Publish Election</span>
      </label>
    </div>

    <br />
    <input
      type="submit"
      @click="updateElection"
      value="Update Election"
      class="
        group
        relative
        w-full
        flex
        justify-center
        py-2
        px-4
        border border-transparent
        text-sm
        font-medium
        rounded-lg
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
      "
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/runtime-core";
import ShowElectionsElectorate from "./ShowElectionsElectorate.vue";
import ShowElectionsQuestion from "./ShowElectionsQuestion.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import moment from "moment-timezone";
import { GDialog } from "gitart-vue-dialog";

import { useStore } from "../../store";

export default defineComponent({
  name: "Update Election Data",

  data() {
    return {
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },

  components: {
    ShowElectionsElectorate,
    ShowElectionsQuestion,
    Datepicker,
    GDialog,
  },

  props: {
    electionID: Object,
  },

  setup(props) {
    const store = useStore();

    let election = store.getters.getShowElectionsData[props.electionID];
    let newElectorate = "";
    let newQuestion = "";
    let mutexElectorate = false;
    var publishElection = false;
    const date = ref();

    // We choose the user's timezone by default
    let userTimeZone = moment.tz.guess();
    let timeZoneOption = `${userTimeZone} (GMT${moment
      .tz(userTimeZone)
      .format("Z")})`;

    onMounted(() => {
      date.value = [election.start_time, election.end_time];
    });

    return {
      store,
      election,
      newElectorate,
      newQuestion,
      mutexElectorate,
      date,
      publishElection,
      timeZoneOption,
    };
  },
  methods: {
    changeEditElection(electionID) {
      this.editElection[electionID] = !this.editElection[electionID];
    },
    deleteQuestion(questionIndex) {
      var showElectionsData = this.$store.getters.getShowElectionsData;

      if (confirm("Are you sure?")) {
        showElectionsData[this.election.id].questions.splice(questionIndex, 1);
        this.$store.commit("updateShowElectionsData", showElectionsData);
      }
    },
    updateCandidates(updateCandidateElement) {
      var showElectionsData = this.$store.getters.getShowElectionsData;
      var questionIndex = updateCandidateElement.questionIndex;
      var newCandidateList = updateCandidateElement.newCandidateList;

      showElectionsData[this.election.id].questions[questionIndex].candidates =
        newCandidateList;

      this.$store.commit("updateShowElectionsData", showElectionsData);
    },
    addNewQuestion() {
      var showElectionsData = this.$store.getters.getShowElectionsData;

      if (!this.newQuestion) {
        alert("Please add the question name");
        return;
      }

      const newQuestionData = {
        description: this.newQuestion,
        constraints: {},
        candidates: [],
      };

      showElectionsData[this.election.id].questions = [
        ...showElectionsData[this.election.id].questions,
        newQuestionData,
      ];

      this.newQuestion = "";

      this.$store.commit("updateShowElectionsData", showElectionsData);
    },
    deleteElectorate(electorate) {
      var showElectionsData = this.$store.getters.getShowElectionsData;

      if (confirm("Are you sure?")) {
        delete showElectionsData[this.election.id].electorates[electorate];
        this.$store.commit("updateShowElectionsData", showElectionsData);
        // also delete any instances of the electorate within the constraints
        for (let questionIndex in showElectionsData[this.election.id]
          .questions) {
          delete showElectionsData[this.election.id].questions[questionIndex]
            .constraints[electorate];
        }
      }
    },
    updateGroup(updateGroupElement) {
      var showElectionsData = this.$store.getters.getShowElectionsData;
      var electorateName = updateGroupElement.electorateName;
      var newGroupList = updateGroupElement.newGroupList;

      showElectionsData[this.election.id].electorates[electorateName].groups =
        newGroupList;

      // intersect the newGroupList with the constraint lists for the given electorate for each question
      for (let questionIndex in showElectionsData[this.election.id].questions) {
        if (
          electorateName in
          showElectionsData[this.election.id].questions[questionIndex]
            .constraints
        ) {
          showElectionsData[this.election.id].questions[
            questionIndex
          ].constraints[electorateName] = showElectionsData[
            this.election.id
          ].questions[questionIndex].constraints[electorateName].filter(
            (value) => newGroupList.includes(value)
          );
        }
      }

      this.$store.commit("updateShowElectionsData", showElectionsData);
    },
    addNewElectorate() {
      var showElectionsData = this.$store.getters.getShowElectionsData;

      if (!this.newElectorate) {
        alert("Please add the electorate name");
        return;
      }

      for (let electorate in this.election.electorates) {
        if (electorate === this.newElectorate) {
          alert("Electorate Already exists");
          return;
        }
      }

      const newElectorateData = {
        name: this.newElectorate,
        is_mutex: this.mutexElectorate,
        groups: [],
      };

      showElectionsData[this.election.id].electorates[this.newElectorate] =
        newElectorateData;

      this.newElectorate = "";

      this.$store.commit("updateShowElectionsData", showElectionsData);
    },
    updateGroupConstraints(updateGroupConstraintsElement) {
      let showElectionsData = this.$store.getters.getShowElectionsData;
      showElectionsData[this.election.id].questions[
        updateGroupConstraintsElement.questionIndex
      ].constraints = updateGroupConstraintsElement.groupConstraints;
      this.$store.commit("updateShowElectionsData", showElectionsData);
    },
    updateElection() {
      var electionTimeZone = this.timeZoneOption.substring(
        0,
        this.timeZoneOption.indexOf(" ")
      );

      let electionData =
        this.$store.getters.getShowElectionsData[this.election.id];
      // convert electorates to an array
      let electoratesArray = [];
      for (let electorate in electionData.electorates) {
        electoratesArray.push(electionData.electorates[electorate]);
      }
      let data = {
        name: electionData.name,
        start_time: this.getUTCFromTimezone(this.date[0], electionTimeZone),
        end_time: this.getUTCFromTimezone(this.date[1], electionTimeZone),
        electorates: electoratesArray,
        questions: electionData.questions,
      };
      axios({
        method: "put",
        url: "/api/elections/" + this.election.id,
        headers: { "Content-Type": "application/json" },
        data: data,
      })
        .then((res) => {
          if (this.publishElection) {
            axios({
              method: "post",
              url: "/api/elections/" + res.data.id + "/publish",
              headers: { "Content-Type": "application/json" },
            })
              .then(() => {
                console.log("Election data");
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  this.errorTitle = "Bad election state";
                  this.errorDescription =
                    "The election was not in a draft state.";
                } else {
                  this.errorTitle = "Unknown Error";
                  this.errorDescription =
                    "Something went wrong. Please try again. If the issue persists, contact an administrator.";
                }
                this.hasError = true;
              });
          }
          alert("Successfully edited election");
          window.location.reload(true);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errorTitle = "Modification not allowed";
            this.errorDescription = "This election cannot be modified.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    loadTimeZoneList() {
      var timeZoneList = [];
      var timeZones = moment.tz.names();
      timeZones.forEach((timeZone) => {
        timeZoneList.push({
          textContent: `${timeZone} (GMT${moment.tz(timeZone).format("Z")})`,
          value: timeZone,
        });
      });
      return timeZoneList;
    },
    getUTCFromTimezone(date, electionTimeZone) {
      var oldDate = moment.tz(
        moment(date).format("YYYY-MM-DDTHH:mm:ss"),
        moment.ISO_8601,
        electionTimeZone
      );
      return oldDate.format();
    },
  },
});
</script>
