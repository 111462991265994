<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div class="max-w-7xl px-20">
    <div class="flex flex-col mx-auto">
      <div
        v-for="(ballot, i) in ballots"
        :key="ballot"
        class="
          text-sm
          font-medium
          text-gray-900
          bg-white
          border border-gray-200
          dark:bg-gray-700 dark:border-gray-600 dark:text-white
        "
      >
        <GDialog v-model="showBallots[i]" max-width="500">
          <div style="color: #000">
            <div style="padding: 20px">
              <h1
                class="
                  text-base text-indigo-600
                  font-semibold
                  tracking-wide
                  uppercase
                  pt-10
                "
              >
                Ballot Information
              </h1>
              <h3 class="text-base tracking-wide pt-2">
                Election ID:
                <p class="font-mono">{{ currentBallot.election_id }}</p>
              </h3>
              <h3 class="text-base tracking-wide pt-2">
                Question ID:
                <p class="font-mono">{{ currentBallot.question_id }}</p>
              </h3>
              <h3 class="text-base tracking-wide pt-2">
                Ballot ID:
                <p class="font-mono">{{ currentBallot.ballot_id }}</p>
              </h3>
              <h3 class="text-base tracking-wide pt-2">
                State:
                <p
                  class="font-mono"
                  v-if="currentBallot.state != 'Unconfirmed'"
                >
                  {{ currentBallot.state }}
                </p>
                <p class="font-mono" v-else>In Progress</p>
              </h3>
              <h3
                class="text-base tracking-wide pt-2 pb-2"
                v-show="currentBallot.state == 'Audited'"
              >
                Chosen Candidate:
                <p class="font-mono">
                  {{ currentBallot.candidate }}
                </p>
              </h3>
              <h3 class="text-base tracking-wide pt-2 pb-2">
                Confirmation Code:
                <p class="font-mono">
                  {{ firstPart(currentBallot.confirmation_code) }}
                </p>
                <p class="font-mono">
                  {{ secondPart(currentBallot.confirmation_code) }}
                </p>
              </h3>
            </div>
            <div
              style="
                display: flex;
                justify-content: flex-end;
                padding: 10px 20px;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
              "
            >
              <button
                class="
                  right-0
                  bg-indigo-600
                  hover:bg-indigo-700
                  md:mt-0
                  mt-4
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded
                "
                @click="showBallots[i] = false"
              >
                Close
              </button>
            </div>
          </div>
        </GDialog>
        <div
          class="
            w-full
            px-4
            py-2
            border-gray-200
            dark:border-gray-600
            hover:bg-gray-100
          "
          @click="populateAndShowBallot(i, ballot.id)"
        >
          <h3 class="text-base tracking-wide pt-2">
            Ballot ID: {{ ballot.id }}
          </h3>
          <div v-if="ballot.state != 'Unconfirmed'">{{ ballot.state }}</div>
          <div v-else>In Progress</div>
        </div>
      </div>

      <div
        class="
          bg-white
          px-4
          py-6
          flex
          items-center
          justify-between
          border-t border-gray-200
          sm:px-6
        "
      >
        <div class="flex flex-col">
          <div class="flex-1 flex justify-between mb-3">
            <button
              class="
                relative
                inline-flex
                items-center
                px-4
                py-2
                border border-gray-300
                text-sm
                font-medium
                rounded-md
                text-gray-700
                bg-white
                hover:bg-gray-50
              "
              v-show="currentPage > 1"
              @click="
                updateBulletinBoard(
                  electionId,
                  questionId,
                  activeBallot,
                  currentPage - 1
                )
              "
            >
              Previous
            </button>
            <button
              class="
                ml-3
                relative
                inline-flex
                items-center
                px-4
                py-2
                border border-gray-300
                text-sm
                font-medium
                rounded-md
                text-gray-700
                bg-white
                hover:bg-gray-50
              "
              @click="
                updateBulletinBoard(
                  electionId,
                  questionId,
                  activeBallot,
                  currentPage + 1
                )
              "
              v-show="ballotsPerPage * currentPage < totalBallots"
            >
              Next
            </button>
          </div>
          <div
            class="
              hidden
              py-3
              sm:flex-1 sm:flex sm:items-center sm:justify-between
            "
          >
            <div>
              <p class="text-sm text-gray-700">
                Showing
                {{ " " }}
                <span class="font-medium">{{
                  1 + ballotsPerPage * (currentPage - 1)
                }}</span>
                {{ " " }}
                to
                {{ " " }}
                <span class="font-medium">{{
                  Math.min(ballotsPerPage * currentPage, totalBallots)
                }}</span>
                {{ " " }}
                of
                {{ " " }}
                <span class="font-medium">{{ totalBallots }}</span>
                {{ " " }}
                results
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";
import crypto from "crypto";
import base32 from "base32";

export default {
  name: "Bulletin Board",
  components: {
    GDialog,
  },
  data() {
    return {
      activeBallot: "",
      currentPage: 1,
      ballotsPerPage: 3,
      ballots: [],
      totalBallots: 0,
      electionId: "",
      questionId: "",
      showBallots: [],
      currentBallot: {},
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  methods: {
    firstPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(0, 25);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
    secondPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(25, 50);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
    // whenever there is a change in the search term, this function is called
    updateBulletinBoard(electionId, questionId, activeBallot, currentPage) {
      this.activeBallot = activeBallot;
      this.currentPage = currentPage;
      this.electionId = electionId;
      this.questionId = questionId;
      let params = {
        page_num: currentPage,
        page_size: this.ballotsPerPage,
      };
      if (activeBallot != "") {
        params.filter_pattern = activeBallot;
      }
      // do axios request
      axios({
        method: "get",
        url: "/api/elections/" + electionId + "/" + questionId + "/ballots",
        headers: { "Content-Type": "application/json" },
        params: params,
      })
        .then((res) => {
          this.totalBallots = res.data.pagination.total;
          // update ballot data
          let ballots = res.data.items;
          let newBallots = [];
          let showBallots = [];
          for (let ballot in ballots) {
            newBallots.push({
              id: ballots[ballot].ballot_id,
              state: ballots[ballot].state,
            });
            showBallots.push(false);
          }
          this.ballots = newBallots;
          this.showBallots = showBallots;
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    populateAndShowBallot(i, ballotId) {
      // send axios request for i's ballot information
      axios({
        method: "get",
        url:
          "/api/elections/" +
          this.electionId +
          "/" +
          this.questionId +
          "/ballots/" +
          ballotId,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          // ballot id, state, if cancelled the vote, confirmation code, JSON dump
          let ballot = res.data;

          if (ballot.state == "Audited") {
            this.currentBallot = {
              ballot_id: ballot.ballot_id,
              question_id: ballot.question_id,
              election_id: ballot.election_id,
              state: ballot.state,
              candidate: ballot.candidate,
              confirmation_code: ballot.confirmation_code,
            };
          } else {
            this.currentBallot = {
              ballot_id: ballot.ballot_id,
              question_id: ballot.question_id,
              election_id: ballot.election_id,
              state: ballot.state,
              confirmation_code: ballot.confirmation_code,
            };
          }
          this.showBallots[i] = true;
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    sha256TruncateP1(signature) {
      // SHA256, encode with base32 Crockford, truncate to 50 characters, group these
      let hashSignature = this.sha256Encode(signature);
      let upperHashSignature = hashSignature.toUpperCase();
      let truncatedUpperHashSignature = upperHashSignature.substring(0, 50);
      let topLine = truncatedUpperHashSignature.substring(0, 25);
      let separatedTopLine = topLine.match(/.{1,5}/g);
      let spacedTopLine = separatedTopLine.join(" ");

      return spacedTopLine;
    },
    sha256TruncateP2(signature) {
      // SHA256, encode with base32 Crockford, truncate to 50 characters, group these
      let hashSignature = this.sha256Encode(signature);
      let upperHashSignature = hashSignature.toUpperCase();
      let truncatedUpperHashSignature = upperHashSignature.substring(0, 50);
      let bottomLine = truncatedUpperHashSignature.substring(25, 50);
      let separatedBottomLine = bottomLine.match(/.{1,5}/g);
      let spacedBottomLine = separatedBottomLine.join(" ");

      return spacedBottomLine;
    },
    sha256Encode(signature) {
      var hash = crypto.createHash("sha256");
      hash.digest = (function (digest) {
        return function () {
          return base32.encode(digest.call(this, "binary"));
        };
      })(hash.digest);
      return hash.update(signature).digest();
    },
    downloadBallotData(ballotId, jsonDump) {
      let text = jsonDump;
      let filename = ballotId + ".json";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
