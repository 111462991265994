<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div class="py-12 bg-white">
    <div
      class="
        max-w-xl
        mx-auto
        px-4
        sm:px-6
        lg:px-8
        w-full
        flex flex-col
        space-y-6
      "
    >
      <div class="lg:text-center">
        <h2
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
          "
        >
          Tally
        </h2>
      </div>

      <div class="w-full max-w-screen-xl mx-auto px-6">
        <div class="flex justify-center p-4 px-3 py-1">
          <div class="w-full max-w-md">
            <div class="bg-white shadow-md rounded-lg px-3 py-3 mb-4">
              <!-- Step 1 -->
              <div>
                <div
                  class="block text-gray-700 text-lg font-semibold py-2 px-2"
                >
                  Step 1: Choose the Election
                </div>
                <div
                  class="
                    block
                    text-gray-700 text-xs
                    font-medium
                    italic
                    pb-4
                    px-2
                    py-2
                  "
                >
                  Elections showing in grey have no tally as they have not yet
                  finished.
                </div>
                <div class="flex items-center bg-gray-200 rounded-md">
                  <div class="pl-2">
                    <svg
                      class="fill-current text-gray-500 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        class="heroicon-ui"
                        d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                      />
                    </svg>
                  </div>
                  <input
                    class="
                      w-full
                      rounded-md
                      bg-gray-100
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      py-2
                      px-2
                    "
                    id="search"
                    type="text"
                    placeholder="Search election"
                    v-model="activeElection.name"
                  />
                </div>
                <div class="overflow-y-auto h-32 bg-gray-100">
                  <div
                    v-for="election in elections"
                    v-show="election.name.includes(activeElection.name)"
                    :key="election.id"
                  >
                    <div class="text-sm">
                      <div
                        v-if="new Date() >= new Date(election.end_time)"
                        @click="setActiveElection(election)"
                        class="
                          flex
                          justify-start
                          cursor-pointer
                          text-gray-700
                          hover:text-blue-400 hover:bg-blue-100
                          rounded-md
                          px-2
                          py-2
                          my-2
                        "
                      >
                        <div class="flex-grow font-medium px-2">
                          {{ election.name }}
                        </div>
                      </div>

                      <div
                        title="Tally is unavailable as the election has not yet finished."
                        aria-label="Tally is unavailable as the election has not yet finished."
                        v-else
                        class="
                          flex
                          justify-start
                          cursor-pointer
                          text-gray-700
                          rounded-md
                          px-2
                          py-2
                          my-2
                          opacity-50
                          cursor-not-allowed
                        "
                        disabled
                      >
                        <div class="flex-grow font-medium px-2">
                          {{ election.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- TODO: uncomment this div -->
              <div v-show="electionNames.includes(activeElection.name)">
                <!-- Step 2 -->
                <div>
                  <div
                    class="block text-gray-700 text-lg font-semibold py-3 px-2"
                  >
                    Step 2: Choose the Question
                  </div>
                  <div class="flex items-center bg-gray-200 rounded-md">
                    <div class="pl-2">
                      <svg
                        class="fill-current text-gray-500 w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="heroicon-ui"
                          d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                        />
                      </svg>
                    </div>
                    <input
                      class="
                        w-full
                        rounded-md
                        bg-gray-100
                        text-gray-700
                        leading-tight
                        focus:outline-none
                        py-2
                        px-2
                      "
                      id="search"
                      type="text"
                      placeholder="Search questions"
                      v-model="activeQuestion.description"
                    />
                  </div>

                  <div class="overflow-y-auto h-32 bg-gray-100">
                    <div
                      v-for="question in questions"
                      v-show="
                        question.description.includes(
                          activeQuestion.description
                        )
                      "
                      :key="question.id"
                      @click="setActiveQuestion(question)"
                    >
                      <div class="text-sm">
                        <div
                          class="
                            flex
                            justify-start
                            cursor-pointer
                            text-gray-700
                            hover:text-blue-400 hover:bg-blue-100
                            rounded-md
                            px-2
                            py-2
                            my-2
                          "
                        >
                          <div class="flex-grow font-medium px-2">
                            {{ question.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <!-- TODO: uncomment this div -->
                <div
                  v-show="
                    electionNames.includes(activeElection.name) &&
                    questionDescriptions.includes(activeQuestion.description)
                  "
                >
                  <div class="overflow-x-auto relative">
                    <table
                      class="
                        w-full
                        text-sm text-left text-gray-500
                        dark:text-gray-400
                      "
                    >
                      <thead
                        class="
                          text-xs text-gray-700
                          uppercase
                          bg-gray-50
                          dark:bg-gray-700 dark:text-gray-400
                        "
                      >
                        <tr>
                          <th scope="col" class="py-3 px-6">Candidate</th>
                          <th scope="col" class="py-3 px-6">For</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="total in totals"
                          :key="total.candidate_name"
                          class="
                            bg-white
                            border-b
                            dark:bg-gray-800 dark:border-gray-700
                          "
                        >
                          <th
                            scope="row"
                            class="
                              py-4
                              px-6
                              font-medium
                              text-gray-900
                              whitespace-nowrap
                              dark:text-white
                            "
                          >
                            {{ total.candidate_name }}
                          </th>
                          <td scope="row" class="py-4 px-6">
                            {{ total.tally }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Verification Page",
  components: {
    GDialog,
  },
  data() {
    return {
      activeElection: reactive({
        name: "",
        id: "",
      }),
      activeQuestion: reactive({
        description: "",
        id: "",
      }),
      elections: reactive([]),
      electionNames: reactive([]),
      questions: reactive({}),
      questionDescriptions: reactive([]),
      totals: reactive([]),
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  created() {
    axios({
      method: "get",
      url: "/api/elections",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        for (let idx in res.data) {
          this.elections.push(res.data[idx]);
          this.electionNames.push(res.data[idx].name);
        }
      })
      .catch(() => {
        this.errorTitle = "Unknown Error";
        this.errorDescription =
          "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        this.hasError = true;
      });
  },
  methods: {
    setActiveElection(election) {
      this.activeElection = {
        name: election.name,
        id: election.id,
      };

      this.activeQuestion = {
        description: "",
        id: "",
      };

      axios({
        method: "get",
        url: "/api/elections/" + election.id,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          this.questions = res.data.questions;
          for (let idx in this.questions) {
            this.questionDescriptions.push(this.questions[idx].description);
          }
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    setActiveQuestion(question) {
      this.activeQuestion = {
        description: question.description,
        id: question.id,
      };

      axios({
        method: "get",
        url:
          "/api/elections/" +
          this.activeElection.id +
          "/" +
          this.activeQuestion.id +
          "/totals",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          this.totals = reactive([]);
          for (let idx in res.data) {
            // NOTE: We are adding each total to the totals list and converting the tally to base 10
            this.totals.push({
              candidate_name: res.data[idx].candidate_name,
              tally: this.base64ToBigInt(res.data[idx].tally).toString(),
            });
          }
          const objectComparisonCallback = (itemA, itemB) => {
            if (BigInt(itemA.tally) < BigInt(itemB.tally)) {
              return 1;
            }

            if (BigInt(itemA.tally) > BigInt(itemB.tally)) {
              return -1;
            }

            return 0;
          };
          this.totals.sort(objectComparisonCallback);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    base64ToBigInt(base64) {
      /**
       * Decode a base64 string to a BigInt.
       * Supports the standard and URL-safe encodings (RFC 3548 sections 3 and 4),
       * with or without padding (padding character assumed to be '=').
       */
      // Trim any padding off the end.
      base64 = base64.replace(/=+$/, "");

      // Iterate over sets of four characters; each set maps to three bytes.
      const setsOfFour = Math.floor(base64.length / 4);
      const leftovers = base64.length % 4;
      if (leftovers === 1) {
        throw new Error(`Invalid base64: ${base64}`);
      }
      let bigint = 0n;
      // For each set of four
      for (let i = 0; i < setsOfFour; i++) {
        // For each character within the set of four
        for (let j = 0; j < 4; j++) {
          // Decode the character into a six-bit value.
          let value = this.base64CharDecode(base64.charCodeAt(i * 4 + j));
          // Shift these bits to their correct position in the final integer.
          let shift = 24 * (setsOfFour - i); // Base shift from the set of four.
          shift -= (j + 1) * 6; // Shift adjustment from the position within the set of four.
          //
          bigint |= BigInt(value) << BigInt(shift);
          //
        }
      }

      // Deal with any leftovers.
      if (leftovers === 2) {
        // A single byte remains, with six bits from the second-last character
        // and two bits from the last character.
        let char1 = base64.charCodeAt(base64.length - 2);
        let char2 = base64.charCodeAt(base64.length - 1);
        let lastByte =
          (this.base64CharDecode(char1) << 2) |
          (this.base64CharDecode(char2) >> 4);
        // Slot it in at the end.
        bigint <<= 8n;
        bigint |= BigInt(lastByte);
      } else if (leftovers === 3) {
        // Two bytes remain, with six bits from the third- and second-last characters
        // and four bits from the last character.
        let char1 = base64.charCodeAt(base64.length - 3);
        let char2 = base64.charCodeAt(base64.length - 2);
        let char3 = base64.charCodeAt(base64.length - 1);
        let lastTwoBytes =
          (this.base64CharDecode(char1) << 10) |
          (this.base64CharDecode(char2) << 4) |
          (this.base64CharDecode(char3) >> 2);
        // Slot them in at the end.
        bigint <<= 16n;
        bigint |= BigInt(lastTwoBytes);
      }
      return bigint;
    },
    base64CharDecode(charCode) {
      /**
       * Decode a single base64 character from its ASCII value.
       * The result will be a six-bit number in the range 0-63.
       * Supports the standard and URL-safe encodings (RFC 3548 sections 3 and 4).
       */
      if (charCode >= 65 && charCode <= 90) {
        // A to Z
        return charCode - 65;
      } else if (charCode >= 97 && charCode <= 122) {
        // a to z
        return charCode - 71;
      } else if (charCode >= 48 && charCode <= 57) {
        // 0 to 9
        return charCode + 4;
      } else if (charCode === 43 || charCode === 45) {
        // + or -
        return 62;
      } else if (charCode === 47 || charCode === 95) {
        // / or _
        return 63;
      } else {
        throw new Error(`Invalid base64 character: ${charCode}`);
      }
    },
  },
});
</script>
