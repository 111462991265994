<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div>
    <p
      class="
        mt-2
        text-3xl
        leading-8
        pb-5
        font-extrabold
        tracking-tight
        text-gray-900
        sm:text-4xl
      "
    >
      Manage Admins
    </p>

    <form @submit.prevent="createAdmin" class="mt-8 space-y-6">
      <input type="hidden" name="remember" value="true" />
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="password" class="sr-only">Coordinator password</label>
          <input
            id="username"
            name="username"
            type="text"
            autocomplete="username"
            required=""
            class="
              appearance-none
              rounded-none
              relative
              block
              w-full
              px-3
              py-2
              border border-gray-300
              placeholder-gray-500
              text-gray-900
              rounded-t-md
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              focus:z-10
              sm:text-sm
            "
            placeholder="Username"
            v-model="username"
          />
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="password"
            required=""
            class="
              appearance-none
              rounded-none
              relative
              block
              w-full
              px-3
              py-2
              border border-gray-300
              placeholder-gray-500
              text-gray-900
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              focus:z-10
              sm:text-sm
            "
            placeholder="Password"
            v-model="password"
          />
          <input
            id="password_check"
            name="password_check"
            type="password"
            autocomplete="password"
            required=""
            class="
              appearance-none
              rounded-none
              relative
              block
              w-full
              px-3
              py-2
              border border-gray-300
              placeholder-gray-500
              text-gray-900
              rounded-b-md
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              focus:z-10
              sm:text-sm
            "
            placeholder="Re-enter Password"
            v-model="password_check"
          />
        </div>
      </div>

      <div>
        <input
          type="submit"
          value="Create Admin"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-md
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        />
      </div>
    </form>

    <div
      class="mt-8 bg-white cursor-pointer shadow rounded-lg p-8 relative z-30"
    >
      <div v-for="admin in admins" :key="admin">
        <div
          class="
            p-4
            mb-3
            items-center
            bg-gray-200
            shadow
            rounded-lg
            cursor-move
            border border-white
          "
        >
          <div class="flex justify-between mb-4">
            <div class="items-center">
              <p
                class="ml-2 text-gray-700 font-semibold font-sans tracking-wide"
              >
                {{ admin }}
              </p>
            </div>
            <div class="flex">
              <button
                aria-label="Delete Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-red-500
                  hover:text-red-600
                "
                v-on:click="deleteAdmin(admin)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "@vue/runtime-core";
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Manage Admins",
  components: {
    GDialog,
  },
  data() {
    return {
      admins: reactive([]),
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  created() {
    // populate from axios request
    //admins = ["Admin 1", "Admin 2", "Admin 3"];
    axios({
      method: "get",
      url: "/api/admins",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        this.admins = res.data;
      })
      .catch((err) => {
        this.errorTitle = "Unknown Error";
        this.errorDescription =
          "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        this.hasError = true;
      });
  },
  methods: {
    createAdmin: function () {
      if (!(this.password === this.password_check)) {
        alert("Password does not match");
        return;
      }

      axios({
        method: "post",
        url: "/api/admins",
        data: {
          username: this.username,
          password: this.password,
        },
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {
          window.location.reload(true);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errorTitle = "Illegal credentials";
            this.errorDescription =
              "Please ensure you are creating an admin with a different name and a strong password.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    deleteAdmin(admin) {
      if (confirm("Are you sure?")) {
        axios({
          method: "delete",
          url: "/api/admins/" + admin,
          headers: { "Content-Type": "application/json" },
        })
          .then(() => {
            window.location.reload(true);
          })
          .catch((err) => {
            if (err.response.status == 404) {
              this.errorTitle = "Not found";
              this.errorDescription =
                "The administrator you tried to delete has not been found";
            } else if (err.response.status == 422) {
              this.errorTitle = "Cannot delete last admin";
              this.errorDescription =
                "You cannot delete the only remaining administrator.";
            } else {
              this.errorTitle = "Unknown Error";
              this.errorDescription =
                "Something went wrong. Please try again. If the issue persists, contact an administrator.";
            }
            this.hasError = true;
          });
      }
    },
  },
});
</script>
