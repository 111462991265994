<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <GDialog v-model="showVerification" max-width="500">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-10
          "
        >
          How to verify a question
        </h1>
        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>In order to verify a question, please follow the below steps.</p>
        </h3>
        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            1. Get a rust toolchain via
            <a href="https://rustup.rs/" target="_blank" style="color: blue"
              >rustup</a
            >.
          </p>
        </h3>
        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            2. Clone the
            <a
              href="https://github.com/DRE-ip-Implementation-Team/dre-ip-backend/"
              target="_blank"
              style="color: blue"
              >dre-ip-backend</a
            >
            repository.
          </p>
        </h3>
        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            3. Run
            <code
              >cargo build --release --all-features --bin verification-cli</code
            >.
          </p>
        </h3>
        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            4. The resulting binary will be
            <code>./target/release/verification-cli</code>.
          </p>
        </h3>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 10px 20px;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        "
      >
        <button
          class="
            right-0
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
          "
          @click="showVerification = false"
        >
          Close
        </button>
      </div>
    </div>
  </GDialog>
  <div class="py-12 bg-white">
    <div
      class="
        max-w-xl
        mx-auto
        px-4
        sm:px-6
        lg:px-8
        w-full
        flex flex-col
        space-y-6
      "
    >
      <div class="lg:text-center">
        <h2
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
          "
        >
          Bulletin Board
        </h2>
      </div>

      <div class="w-full max-w-screen-xl mx-auto px-6">
        <div class="flex justify-center p-4 px-3 py-1">
          <div class="w-full max-w-md">
            <div class="bg-white shadow-md rounded-lg px-3 py-3 mb-4">
              <!-- Step 1 -->
              <div>
                <div
                  class="block text-gray-700 text-lg font-semibold py-2 px-2"
                >
                  Step 1: Choose what to Verify
                </div>
                <div class="overflow-y-auto h-32 bg-gray-100">
                  <div @click="setVerify(true)">
                    <div class="text-sm">
                      <div
                        class="
                          flex
                          justify-start
                          cursor-pointer
                          text-gray-700
                          hover:text-blue-400 hover:bg-blue-100
                          rounded-md
                          px-2
                          py-2
                          my-2
                        "
                      >
                        <div class="flex-grow font-medium px-2">
                          Verify Ballot (recommended for voters)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="setVerify(false)">
                    <div class="text-sm">
                      <div
                        class="
                          flex
                          justify-start
                          cursor-pointer
                          text-gray-700
                          hover:text-blue-400 hover:bg-blue-100
                          rounded-md
                          px-2
                          py-2
                          my-2
                        "
                      >
                        <div class="flex-grow font-medium px-2">
                          Verify Question (recommended for administrators)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="verifyBallot !== null">
                <div
                  class="block text-gray-700 text-lg font-semibold py-2 px-2"
                >
                  Step 2: Choose the Election
                </div>
                <div class="flex items-center bg-gray-200 rounded-md">
                  <div class="pl-2">
                    <svg
                      class="fill-current text-gray-500 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        class="heroicon-ui"
                        d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                      />
                    </svg>
                  </div>
                  <input
                    class="
                      w-full
                      rounded-md
                      bg-gray-100
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      py-2
                      px-2
                    "
                    id="search"
                    type="text"
                    placeholder="Search election"
                    v-model="activeElection.id"
                  />
                </div>
                <div class="overflow-y-auto h-32 bg-gray-100">
                  <div
                    v-for="election in elections"
                    v-show="String(election.id).includes(activeElection.id)"
                    :key="election.id"
                    @click="setActiveElection(election)"
                  >
                    <div class="text-sm">
                      <div
                        class="
                          flex
                          justify-start
                          cursor-pointer
                          text-gray-700
                          hover:text-blue-400 hover:bg-blue-100
                          rounded-md
                          px-2
                          py-2
                          my-2
                        "
                      >
                        <div class="flex-grow font-medium px-2">
                          {{ election.id }} ({{ election.name }})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="
                  verifyBallot !== null &&
                  electionIds.includes(activeElection.id)
                "
              >
                <!-- Step 2 -->
                <div>
                  <div
                    class="block text-gray-700 text-lg font-semibold py-3 px-2"
                  >
                    Step 3: Choose the Question
                  </div>
                  <div class="flex items-center bg-gray-200 rounded-md">
                    <div class="pl-2">
                      <svg
                        class="fill-current text-gray-500 w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="heroicon-ui"
                          d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                        />
                      </svg>
                    </div>
                    <input
                      class="
                        w-full
                        rounded-md
                        bg-gray-100
                        text-gray-700
                        leading-tight
                        focus:outline-none
                        py-2
                        px-2
                      "
                      id="search"
                      type="text"
                      placeholder="Search questions"
                      v-model="activeQuestion.id"
                    />
                  </div>

                  <div class="overflow-y-auto h-32 bg-gray-100">
                    <div
                      v-for="question in questions"
                      v-show="String(question.id).includes(activeQuestion.id)"
                      :key="question.id"
                      @click="setActiveQuestion(question)"
                    >
                      <div class="text-sm">
                        <div
                          class="
                            flex
                            justify-start
                            cursor-pointer
                            text-gray-700
                            hover:text-blue-400 hover:bg-blue-100
                            rounded-md
                            px-2
                            py-2
                            my-2
                          "
                        >
                          <div class="flex-grow font-medium px-2">
                            {{ question.id }} ({{ question.description }})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-show="
                    verifyBallot !== null &&
                    verifyBallot &&
                    electionIds.includes(activeElection.id) &&
                    questionIds.includes(activeQuestion.id)
                  "
                >
                  <div
                    class="block text-gray-700 text-lg font-semibold py-3 px-2"
                  >
                    Step 4: Choose the Ballot
                  </div>
                  <div class="flex items-center bg-gray-200 rounded-md">
                    <div class="pl-2">
                      <svg
                        class="fill-current text-gray-500 w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="heroicon-ui"
                          d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                        />
                      </svg>
                    </div>
                    <input
                      class="
                        w-full
                        rounded-md
                        bg-gray-100
                        text-gray-400
                        leading-tight
                        focus:outline-none
                        py-2
                        px-2
                      "
                      id="search"
                      type="text"
                      placeholder="Search ballot"
                      v-model="activeBallot"
                    />
                  </div>
                </div>
                <div
                  v-show="
                    verifyBallot !== null &&
                    !verifyBallot &&
                    electionIds.includes(activeElection.id) &&
                    questionIds.includes(activeQuestion.id)
                  "
                >
                  <div
                    class="block text-gray-700 text-lg font-semibold py-3 px-2"
                  >
                    Step 4: Download Election Data
                  </div>
                  <div
                    class="flex items-center bg-gray-200 rounded-md flex-rows"
                  >
                    <button
                      class="
                        mx-2
                        right-0
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      @click="downloadQuestionDumps()"
                    >
                      Download Question Dumps
                    </button>
                    <button
                      class="
                        mx-2
                        right-0
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      v-show="electionHasFinished"
                      @click="downloadQuestionTotals()"
                    >
                      View Election Tallies
                    </button>
                    <button
                      class="
                        mx-2
                        right-0
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      @click="showVerification = true"
                    >
                      Download Verification Tool
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="mt-7"
                v-show="
                  verifyBallot !== null &&
                  verifyBallot &&
                  electionIds.includes(activeElection.id) &&
                  questionIds.includes(activeQuestion.id)
                "
              >
                <BulletinBoard ref="bulletinBoard" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: replace with step 3 -->
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import axios from "axios";
import BulletinBoard from "../components/BulletinBoard.vue";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Verification Page",
  components: {
    BulletinBoard,
    GDialog,
  },
  data() {
    return {
      activeElection: reactive({
        name: "",
        id: "",
      }),
      activeQuestion: reactive({
        description: "",
        id: "",
      }),
      activeBallot: "",
      elections: reactive([]),
      electionIds: reactive([]),
      questions: reactive({}),
      questionIds: reactive([]),
      verifyBallot: null,
      electionHasFinished: null,
      hasError: false,
      showVerification: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  created() {
    axios({
      method: "get",
      url: "/api/elections",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        this.elections = res.data;

        for (let idx in this.elections) {
          this.electionIds.push(this.elections[idx].id);
        }
      })
      .catch((err) => {
        this.errorTitle = "Unknown Error";
        this.errorDescription =
          "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        this.hasError = true;
      });
  },
  methods: {
    setVerify(val) {
      this.verifyBallot = val;
      this.activeElection = {
        name: "",
        id: "",
      };
      this.electionHasFinished = null;
      this.activeQuestion = {
        description: "",
        id: "",
      };
      this.questions = [];
      this.questionIds = [];
      this.activeBallot = "";
    },
    setActiveElection(election) {
      this.activeElection = {
        name: election.name,
        id: election.id,
      };
      this.electionHasFinished = null;
      this.activeQuestion = {
        description: "",
        id: "",
      };
      this.questions = [];
      this.questionIds = [];
      this.activeBallot = "";

      axios({
        method: "get",
        url: "/api/elections/" + election.id,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          this.questions = res.data.questions;
          for (let idx in this.questions) {
            this.questionIds.push(this.questions[idx].id);
          }
          this.electionHasFinished = new Date(res.data.end_time) < new Date();
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    setActiveQuestion(question) {
      this.activeQuestion = {
        description: question.description,
        id: question.id,
      };

      this.activeBallot = "";

      this.$refs.bulletinBoard.updateBulletinBoard(
        this.activeElection.id,
        this.activeQuestion.id,
        this.activeBallot,
        1
      );
    },
    downloadQuestionDumps() {
      axios({
        method: "get",
        url:
          "/api/elections/" +
          this.activeElection.id +
          "/" +
          this.activeQuestion.id +
          "/dump",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          // download it
          let text = JSON.stringify(res.data);
          let filename =
            this.activeElection.id +
            "_" +
            this.activeQuestion.id +
            "_dumps.json";
          let element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/json;charset=utf-8," + encodeURIComponent(text)
          );
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    downloadQuestionTotals() {
      this.$router.replace("/tally");
    },
  },
  watch: {
    activeBallot() {
      this.$refs.bulletinBoard.updateBulletinBoard(
        this.activeElection.id,
        this.activeQuestion.id,
        this.activeBallot,
        1
      );
    },
  },
});
</script>
