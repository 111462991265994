<template>
  <div>
    <div :key="index" v-for="(question, index) in questions">
      <Question
        :question="question"
        :index="index"
        @update-candidates="updateCandidates"
        @delete-question="deleteQuestion"
        @update-group-constraints="updateGroupConstraints"
      />
    </div>
  </div>
</template>

<script>
import Question from "./Question.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Questions",
  props: {
    questions: Array,
  },
  components: {
    Question,
  },
  methods: {
    updateCandidates(updateCandidateElement) {
      this.$emit("update-candidates", updateCandidateElement);
    },
    deleteQuestion(questionIndex) {
      this.$emit("delete-question", questionIndex);
    },
    updateGroupConstraints(groupConstraintsElement) {
      this.$emit("update-group-constraints", groupConstraintsElement);
    },
  },
});
</script>
