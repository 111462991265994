<template>
  <div>
    <div class="bg-gray-100 rounded-t-lg flex flex-col space-y-4">
      <Electorates
        @update-group="updateGroup"
        @delete-electorate="deleteElectorate"
        :electorates="$store.getters.getElectorates"
      />

      <!-- Add Electorate button -->
      <div>
        <form @submit.prevent="addNewElectorate">
          <div class="form-control">
            <input
              type="text"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-yellow-500
                focus:border-yellow-500
                focus:z-10
                sm:text-sm
              "
              name="electorateName"
              v-model="newElectorate"
              placeholder="Add new Electorate"
            />
          </div>
          <div class="flex px-3 py-4">
            <label class="flex items-center">
              <input type="checkbox" name="options" v-model="mutexElectorate" />
              <span class="ml-2 text-sm">Single Select Electorate</span>
            </label>
          </div>
          <input
            type="submit"
            value="Add Electorate"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-b-lg
              text-white
              bg-yellow-600
              hover:bg-yellow-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-yellow-500
            "
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import Electorates from "./Electorates.vue";

export default defineComponent({
  name: "Electorates Tab",
  components: {
    Electorates,
  },
  setup() {
    let newElectorate = "";
    let mutexElectorate = false;
    return {
      newElectorate,
      mutexElectorate,
    };
  },
  methods: {
    addNewElectorate() {
      if (!this.newElectorate) {
        alert("Please add the electorate name");
        return;
      }
      let electorates = this.$store.getters.getElectorates;
      if (this.newElectorate in electorates) {
        alert("There is already an electorate with this name");
        return;
      }

      const newElectorate = {
        name: this.newElectorate,
        is_mutex: this.mutexElectorate,
        groups: [],
      };

      electorates[this.newElectorate] = newElectorate;

      this.newElectorate = "";
      this.mutexElectorate = false;
      this.$store.commit("updateElectorates", electorates);
    },
    updateGroup(updateGroupElement) {
      let electorates = this.$store.getters.getElectorates;
      // this may not be required, but a reference needs to be updated rather than a value
      electorates[updateGroupElement.electorateName] = {
        name: electorates[updateGroupElement.electorateName].name,
        is_mutex: electorates[updateGroupElement.electorateName].is_mutex,
        groups: updateGroupElement.newGroupList,
      };

      this.$store.commit("updateElectorates", electorates);

      // intersect the newGroupList with the constraint lists for the given electorate for each question
      var questions = this.$store.getters.getQuestions;
      for (let questionIndex in questions) {
        if (
          updateGroupElement.electorateName in
          questions[questionIndex].constraints
        ) {
          questions[questionIndex].constraints = questions[
            questionIndex
          ].constraints.filter((value) =>
            updateGroupElement.newGroupList.includes(value)
          );
        }
      }
      this.$store.commit("updateQuestions", questions);
    },
    deleteElectorate(electorateName) {
      var electorates = this.$store.getters.getElectorates;
      var questions = this.$store.getters.getQuestions;
      if (confirm("Are you sure?")) {
        delete electorates[electorateName];
        this.$store.commit("updateElectorates", electorates);
        // also delete any instances of the electorate within the constraints
        for (let question in questions) {
          delete questions[question].constraints[electorateName];
        }
        this.$store.commit("updateQuestions", questions);
      }
    },
  },
});
</script>
