<script>
import axios from "axios";
import { defineComponent } from "vue";
import { useStore } from "../store";
import { useRouter } from "../router";

export default defineComponent({
  name: "Logout",
  setup() {
    const store = useStore();
    const router = useRouter();
    axios({
      method: "delete",
      url: "/api/auth",
      headers: { "Content-Type": "application/json" },
    }).then(() => {
      store.commit("updateLoginState", "Unauthenticated");
      router.replace("/");
    });
  },
});
</script>
