<template>
  <div class="py-12 bg-white">
    <div
      class="
        max-w-xl
        mx-auto
        px-4
        sm:px-6
        lg:px-8
        w-full
        flex flex-col
        space-y-6
      "
    >
      <div class="lg:text-center">
        <h2
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
          "
        >
          Election Management
        </h2>
      </div>

      <div>
        <div class="w-full px-2 sm:px-0">
          <TabGroup>
            <TabList class="flex p-1 space-x-1 bg-indigo-50 rounded-xl">
              <Tab
                :class="[
                  'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
                ]"
              >
                <button
                  :class="[
                    'bg-indigo-700 w-full py-2.5 rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                  ]"
                  v-on:click="component = 'createElection'"
                >
                  Create Election
                </button>
              </Tab>
              <Tab
                :class="[
                  'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
                ]"
              >
                <button
                  :class="[
                    'bg-indigo-700 w-full py-2.5 rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                  ]"
                  v-on:click="component = 'createAdmin'"
                >
                  Manage Admins
                </button>
              </Tab>
              <Tab
                :class="[
                  'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
                ]"
              >
                <button
                  :class="[
                    'bg-indigo-700 w-full py-2.5 rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                  ]"
                  v-on:click="component = 'showElections'"
                >
                  Manage Elections
                </button>
              </Tab>
              <Tab
                :class="[
                  'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
                ]"
              >
                <button
                  :class="[
                    'bg-indigo-700 w-full py-2.5 rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                  ]"
                  v-on:click="component = 'archivedElections'"
                >
                  Archived Elections
                </button>
              </Tab>
            </TabList>
          </TabGroup>
        </div>
      </div>
      <keep-alive>
        <component v-bind:is="component"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { TabGroup, TabList, Tab } from "@headlessui/vue";

import CreateElection from "../components/election_management/CreateElection.vue";
import CreateAdmin from "../components/election_management/CreateAdmin.vue";
import ShowElections from "../components/election_management/ShowElections.vue";
import ArchivedElections from "../components/election_management/ArchivedElections.vue";

export default defineComponent({
  name: "Election Management",
  components: {
    createElection: CreateElection,
    createAdmin: CreateAdmin,
    showElections: ShowElections,
    archivedElections: ArchivedElections,
    TabGroup,
    TabList,
    Tab,
  },
  data() {
    return {
      component: "createElection",
    };
  },
});
</script>
