import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Homepage from "../views/Homepage.vue";
import Registration from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import SubmitOtpPage from "../views/SubmitOtpPage.vue";
import CoordinatorLogin from "../views/CoordinatorLogin.vue";
import ElectionManagement from "../views/ElectionManagement.vue";

import VotingElections from "../components/voting/VotingElections.vue";
import VotingGroupSelection from "../components/voting/VotingGroupSelection.vue";
import VotingQuestions from "../components/voting/VotingQuestions.vue";
import VotingStageTwo from "../components/voting/VotingStageTwo.vue";
import VotingAfterStageTwo from "../components/voting/VotingAfterStageTwo.vue";
import VotingFinished from "../components/voting/VotingFinished.vue";
import Verification from "../components/Verification.vue";
import Tally from "../components/Tally.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
    meta: {
      title: "Homepage",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Registration,
    meta: {
      title: "Login",
    },
    props: true,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      title: "Logout",
    },
    props: true,
  },
  {
    path: "/otp",
    name: "SubmitOtpPage",
    component: SubmitOtpPage,
    meta: {
      title: "Login",
    },
    props: true,
  },
  {
    path: "/verification",
    name: "Verification",
    component: Verification,
    meta: {
      title: "Bulletin Board",
    },
    props: true,
  },
  {
    path: "/tally",
    name: "Tally",
    component: Tally,
    meta: {
      title: "Tally",
    },
    props: true,
  },
  {
    path: "/coordinatorLogin",
    name: "CoordinatorLogin",
    component: CoordinatorLogin,
    meta: {
      title: "Coordinator Login",
    },
    props: true,
  },
  {
    path: "/electionManagement",
    name: "ElectionManagement",
    component: ElectionManagement,
    meta: {
      title: "Election Management",
    },
    props: true,
  },

  // Voting Page Routes
  // Step 1 - Show Elections to voters
  {
    path: "/votingElections",
    name: "VotingElections",
    component: VotingElections,
    meta: {
      title: "Voting",
    },
    props: true,
  },

  // Step 2 - Show Groups to voters
  {
    path: "/votingGroups",
    name: "VotingGroupSelection",
    component: VotingGroupSelection,
    meta: {
      title: "Voting",
    },
    props: true,
  },

  // Step 3 - Show Questions to voters
  {
    path: "/votingQuestions",
    name: "VotingQuestions",
    component: VotingQuestions,
    meta: {
      title: "Voting",
    },
    props: true,
  },

  // Step 4 - Show Stage Two to voters
  {
    path: "/votingStageTwo",
    name: "VotingStageTwo",
    component: VotingStageTwo,
    meta: {
      title: "Voting",
    },
    props: true,
  },

  // Step 5 - Show After Stage Two to voters
  {
    path: "/votingAfterStageTwo",
    name: "VotingAfterStageTwo",
    component: VotingAfterStageTwo,
    meta: {
      title: "Voting",
    },
    props: true,
  },

  // Step 6 - Finish
  {
    path: "/votingFinished",
    name: "VotingFinished",
    component: VotingFinished,
    meta: {
      title: "Voting",
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export function useRouter(): typeof router {
  return router;
}

export default router;
