<template>
  <div class="mt-5 bg-gradient-to-b from-gray-100 to-gray-200 my-8 p-6">
    <div>
      <h3 class="text-base text-indigo-500 font-semibold tracking-wide mb-4">
        {{ electorate.name }}
        <i class="fas fa-times"></i>
      </h3>

      <!-- TODO: Add toggle for Mutex Group -->
      <p v-if="electorate.is_mutex" class="text-sm">Single Select Electorate</p>
      <div v-for="group in electorate.groups" :key="group" class="relative">
        <Group @delete-group="deleteGroup" :group="group" />
      </div>

      <br />

      <!-- Add group -->
      <div>
        <form @submit.prevent="addNewGroup">
          <div class="form-control">
            <!-- <label>Electorate Name</label> -->
            <input
              type="text"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-64
                mx-auto
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              name="groupName"
              v-model="newGroup"
              placeholder="Add new group"
            />
          </div>
          <input
            type="submit"
            value="Add Group"
            class="
              group
              relative
              w-64
              mb-5
              mx-auto
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-b-lg
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          />
        </form>
      </div>
      <!-- Delete Electorate -->
      <div>
        <button
          v-on:click="deleteElectorate(electorate.name)"
          type="submit"
          class="
            group
            relative
            w-64
            mb-5
            mx-auto
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-lg
            text-white
            bg-red-600
            hover:bg-red-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-red-500
          "
        >
          Delete Electorate
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Group from "./Group.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShowElectionsElectorate",
  components: {
    Group,
  },
  props: {
    electorate: Object,
  },
  methods: {
    addNewGroup() {
      if (!this.newGroup) {
        alert("Please add the group name");
        return;
      }

      for (let group in this.electorate.groups) {
        if (this.electorate.groups[group] === this.newGroup) {
          alert("Group Already exists");
          return;
        }
      }

      var newGroupList = [...this.electorate.groups, this.newGroup];

      var updateGroupElement = {
        electorateName: this.electorate.name,
        newGroupList: newGroupList,
      };

      this.newGroup = "";

      this.$emit("update-group", updateGroupElement);
    },

    deleteGroup(myGroup) {
      var newGroupList = this.electorate.groups.filter(
        (group) => group != myGroup
      );

      const deleteGroupElement = {
        electorateName: this.electorate.name,
        newGroupList: newGroupList,
      };

      this.$emit("update-group", deleteGroupElement);
    },

    deleteElectorate(electorateID) {
      this.$emit("delete-electorate", electorateID);
    },
  },
});
</script>
