<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div
    class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex flex-col space-y-6"
  >
    <div class="lg:text-center">
      <h1
        class="
          text-base text-indigo-600
          font-semibold
          tracking-wide
          uppercase
          pt-10
        "
      >
        Voting Page
      </h1>
      <h3
        class="text-base tracking-wide pt-2 pb-10"
        v-if="allowedQuestions.length !== 0"
      >
        Please answer the following questions
      </h3>
      <h3 class="text-base tracking-wide pt-2 pb-10" v-else>
        You have no more questions to vote on in this election
      </h3>
    </div>
    <div>
      <ul id="elections">
        <li v-for="question in allowedQuestions" :key="question.id">
          <div class="container mx-auto pb-10">
            <div
              role="listitem"
              class="
                bg-white
                cursor-pointer
                shadow
                rounded-lg
                p-8
                relative
                z-30
              "
            >
              <div class="mx-auto">
                <h2 class="text-2xl font-semibold leading-6 text-gray-800 mb-5">
                  {{ question.description }}
                </h2>

                <div class="flex justify-center">
                  <div>
                    <div
                      v-for="candidate in question.candidates"
                      :key="candidate"
                    >
                      <div class="form-check mb-1">
                        <input
                          class="
                            form-check-input
                            appearance-none
                            h-4
                            w-4
                            border border-gray-300
                            rounded-full
                            bg-white
                            checked:bg-blue-600 checked:border-blue-600
                            focus:outline-none
                            transition
                            duration-200
                            mt-1
                            align-top
                            bg-no-repeat bg-center bg-contain
                            float-left
                            mr-2
                            cursor-pointer
                          "
                          type="radio"
                          :id="candidate"
                          :value="candidate"
                          v-model="questionSelections[question.id]"
                        />
                        <label
                          class="form-check-label text-gray-800"
                          :for="candidate"
                        >
                          {{ candidate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="flex justify-end">
        <button
          v-if="
            allowedQuestions.length !== 0 &&
            Object.keys(questionSelections).length > 0
          "
          @click="sendQuestionChoices"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
        >
          Submit Votes
        </button>
        <button
          v-else-if="allowedQuestions.length !== 0"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
            opacity-50
            cursor-not-allowed
          "
          disabled
        >
          Submit Votes
        </button>
        <button
          v-else
          @click="$router.replace('/votingElections')"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
        >
          Return to Elections
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "../../store";
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Voting Page",

  components: {
    GDialog,
  },

  data() {
    return {
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },

  setup() {
    const store = useStore();
    let voterElections = store.getters.getVoterElections;
    let currentElection = voterElections.currentElection;
    let allowedQuestions = reactive([]);
    let questionSelections = reactive({});

    // Get questions based on the group choices
    axios({
      method: "get",
      url: "/api/elections/" + currentElection + "/questions/allowed",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        let allowedQuestionsDictionary = res.data;

        axios({
          method: "get",
          url: "/api/elections/" + currentElection,
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            let questions = res.data["questions"];

            // Filter out the Questions that are allowed
            for (let questionID in questions) {
              // Sanity Check: Ensure the question ID is in the allowed questions list
              if (questionID in allowedQuestionsDictionary) {
                if (!allowedQuestionsDictionary[questionID]) {
                  allowedQuestions.push({
                    id: Number(questionID),
                    description: questions[questionID].description,
                    candidates: questions[questionID].candidates,
                  });
                }
              }
            }
          })
          .catch((err) => {
            if (err.response.status == 404) {
              this.errorTitle = "Not found or unauthorised";
              this.errorDescription =
                "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
            } else {
              this.errorTitle = "Unknown Error";
              this.errorDescription =
                "Something went wrong. Please try again. If the issue persists, contact an administrator.";
            }
            this.hasError = true;
          });
      })
      .catch((err) => {
        if (err.response.status == 404) {
          this.errorTitle = "Not found or unauthorised";
          this.errorDescription =
            "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
        } else {
          this.errorTitle = "Unknown Error";
          this.errorDescription =
            "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        }
        this.hasError = true;
      });
    return {
      allowedQuestions,
      questionSelections,
    };
  },

  methods: {
    getQuestions() {
      // TODO: Get Election and group choices
      let voterElections = this.$store.getters.getVoterElections;
      let currentElection = voterElections.currentElection;
      let electionValues = undefined;

      for (let electionNumber in voterElections["elections"]) {
        electionValues = voterElections["elections"][electionNumber];
        if (electionValues.id === currentElection)
          return electionValues.questions;
      }
      return [];
    },
    sendQuestionChoices() {
      // this.groupSelections holds the group selections
      let selections = this.questionSelections;
      let selectionsArray = [];
      // convert any mutex electorate's group value to an array containing it
      for (const [key, value] of Object.entries(selections)) {
        selectionsArray.push({
          question: Number(key),
          candidate: value,
        });
      }

      // send axios request
      let voterElections = this.$store.getters.getVoterElections;
      let currentElection = voterElections.currentElection;
      axios({
        method: "post",
        url: "/api/elections/" + currentElection + "/votes/cast",
        data: selectionsArray,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          let allowedQuestionsDict = {};
          for (let question in this.allowedQuestions) {
            allowedQuestionsDict[this.allowedQuestions[question].id] =
              this.allowedQuestions[question].description;
          }
          let ballots = res.data;
          let ballotsForStore = [];
          for (let ballot in ballots) {
            let ballotForStore = {
              ballot_id: ballots[ballot].ballot_id,
              question_id: ballots[ballot].question_id,
              election_id: ballots[ballot].election_id,
              question_description:
                allowedQuestionsDict[ballots[ballot].question_id],
              state: ballots[ballot].state,
              confirmation_code: ballots[ballot].confirmation_code,
              signature: ballots[ballot].signature,
              candidate: selectionsArray[ballot].candidate,
            };
            ballotsForStore.push(ballotForStore);
          }
          // move the store commit here so ballots get completely overwritten
          this.$store.commit("updateBallots", ballotsForStore);
          this.$router.replace("/votingStageTwo");
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not found or unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
  },
});
</script>
