<template>
  <div>
    <div
      :key="electorateName"
      v-for="(electorate, electorateName) in electorates"
    >
      <Electorate
        :electorate="electorate"
        @update-group="updateGroup"
        @delete-electorate="deleteElectorate"
      />
    </div>
  </div>
</template>

<script>
import Electorate from "./Electorate.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Electorates",
  props: {
    electorates: Object,
  },
  components: {
    Electorate,
  },
  methods: {
    updateGroup(updateGroupElement) {
      this.$emit("update-group", updateGroupElement);
    },
    deleteElectorate(electorateID) {
      this.$emit("delete-electorate", electorateID);
    },
  },
});
</script>
