<template>
  <div>
    <OtpForm />
  </div>
</template>

<script>
import OtpForm from "../components/OtpForm.vue";

export default {
  name: "SubmitOtpPage",
  components: {
    OtpForm,
  },
};
</script>
