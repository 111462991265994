
import Candidate from "./Candidate.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShowElectionsQuestion",
  components: {
    Candidate,
  },
  props: {
    question: Object,
    electionID: String,
    questionIndex: Number,
  },
  setup() {
    return {
      newCandidate: "",
    };
  },
  methods: {
    addNewCandidate() {
      if (!this.newCandidate) {
        alert("Please add the candidate name");
        return;
      }

      let candidates = this.question?.candidates;

      for (let candidate in candidates) {
        if (candidates[candidate] == this.newCandidate) {
          alert("Candidate already exists");
          return;
        }
      }

      let newCandidateList = [...candidates, this.newCandidate];

      const updateCandidateElement = {
        questionIndex: this.questionIndex,
        newCandidateList: newCandidateList,
      };

      this.newCandidate = "";

      this.$emit("update-candidates", updateCandidateElement);
    },

    deleteQuestion(questionIndex: number) {
      this.$emit("delete-question", questionIndex);
    },

    deleteCandidate(candidateToDelete: string) {
      const newCandidateList = this.question?.candidates.filter(
        (candidate: string) => candidate != candidateToDelete
      );

      const updateCandidateElement = {
        questionIndex: this.questionIndex,
        newCandidateList: newCandidateList,
      };

      this.$emit("update-candidates", updateCandidateElement);
    },

    ticked(electorateName: string, group: string) {
      if (electorateName in this.question?.constraints) {
        return this.question?.constraints[electorateName].includes(group);
      }
      return false;
    },

    updateGroupConstraints(
      checked: boolean,
      electorateName: string,
      group: string
    ) {
      // if the group already exists, remove it
      // else, add it

      let groupConstraints = this.question?.constraints;
      let isTicked = this.ticked(electorateName, group);
      if (isTicked && !checked) {
        groupConstraints[electorateName].splice(
          groupConstraints[electorateName].indexOf(group),
          1
        );
      }
      if (!isTicked && checked) {
        if (!(electorateName in groupConstraints)) {
          groupConstraints[electorateName] = [];
        }
        groupConstraints[electorateName].push(group);
      }

      const updateGroupConstraintsElement = {
        questionIndex: this.questionIndex,
        groupConstraints: groupConstraints,
      };

      this.$emit("update-group-constraints", updateGroupConstraintsElement);
    },
  },
});
