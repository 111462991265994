<template>
  <div
    class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex flex-col space-y-6"
  >
    <div class="lg:text-center">
      <h1
        class="
          text-base text-indigo-600
          font-semibold
          tracking-wide
          uppercase
          pt-10
        "
      >
        Voting Page
      </h1>
      <h3 class="text-base tracking-wide pt-2 pb-10">Check your receipts</h3>
    </div>
    <div>
      <ul id="ballots">
        <li v-for="(ballot, i) in ballots" :key="ballot.ballot_id">
          <div class="container mx-auto pb-10">
            <div
              role="listitem"
              class="
                bg-white
                cursor-pointer
                shadow
                rounded-lg
                p-8
                relative
                z-30
              "
            >
              <h1
                class="
                  text-base text-indigo-600
                  font-semibold
                  tracking-wide
                  uppercase
                  pt-5
                  mb-10
                "
              >
                Question: {{ ballot.question_description }}
              </h1>
              <GDialog v-model="showCrypto[i]" max-width="500">
                <div style="color: #000">
                  <div style="padding: 20px">
                    <h1
                      class="
                        text-base text-indigo-600
                        font-semibold
                        tracking-wide
                        uppercase
                        pt-10
                      "
                    >
                      How to verify your vote
                    </h1>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Election ID:
                      <p class="font-mono">{{ ballot.election_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Question ID:
                      <p class="font-mono">{{ ballot.question_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Ballot ID:
                      <p class="font-mono">{{ ballot.ballot_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2">
                      State:
                      <p class="font-mono">{{ ballot.state }}</p>
                    </h3>
                    <h3
                      class="text-base tracking-wide pt-2 pb-2"
                      v-show="ballot.state == 'Audited'"
                    >
                      Chosen Candidate:
                      <p class="font-mono">
                        {{ ballot.candidate }}
                      </p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Confirmation Code:
                      <p class="font-mono">
                        {{ firstPart(ballot.confirmation_code) }}
                      </p>
                      <p class="font-mono">
                        {{ secondPart(ballot.confirmation_code) }}
                      </p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      <p>
                        In order to verify your vote, take a copy of the
                        election ID, question ID, ballot ID and confirmation
                        code above. Then, head over to the public bulletin
                        board, enter the IDs to find your ballot and check the
                        corresponding confirmation code matches. You can access
                        the bulletin board by clicking on
                        <router-link
                          to="/verification"
                          target="_blank"
                          style="color: #0070d2"
                          >{{ windowUrl + "/#/verification" }}</router-link
                        >
                        (it will open in a new tab).
                      </p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      <p>
                        By checking this, you are confirming that your ballot
                        has been recorded as cast, which is one of the three
                        elements of end-to-end verifiability that makes this
                        system provably trustworthy. If you would like to learn
                        more about this voting system's guarantees of trust,
                        speak to your local vote administrator.
                      </p>
                    </h3>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      padding: 10px 20px;
                      border-top: 1px solid rgba(0, 0, 0, 0.12);
                    "
                  >
                    <button
                      class="
                        right-0
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      @click="showCrypto[i] = false"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </GDialog>
              <div
                class="
                  bg-green-100
                  border-t border-b border-green-500
                  text-green-700
                  px-4
                  py-3
                  mb-5
                "
                role="alert"
                v-if="ballot.is_confirmed"
                @click="showCrypto[i] = true"
              >
                <p class="font-bold">Vote Confirmed</p>
              </div>
              <div
                class="
                  bg-yellow-100
                  border-t border-b border-yellow-500
                  text-yellow-700
                  px-4
                  py-3
                  mb-5
                "
                role="alert"
                v-else
              >
                <p class="font-bold">
                  Vote Cancelled (Cancelled Vote: {{ ballot.candidate }})
                </p>
              </div>
              <div
                class="
                  flex
                  items-center
                  bg-white
                  text-gray text-sm
                  hover:text-blue-500
                  font-bold
                "
                role="alert"
                @click="showCrypto[i] = true"
              >
                <svg
                  class="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
                  />
                </svg>
                <p>(Optional) Verify your vote</p>
                <!-- <p>
                  Please click here if you would like to verify your ballot.
                </p> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="flex justify-end">
        <button
          v-if="noMoreQuestions.val"
          @click="$router.replace('/votingFinished')"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
        >
          Finish voting
        </button>
        <button
          v-else
          @click="$router.replace('/votingElections')"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
        >
          Return to voting
        </button>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "../../store";
import useClipboard from "vue-clipboard3";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import axios from "axios";

export default defineComponent({
  name: "Voting Page",

  components: {
    GDialog,
  },
  setup() {
    const { toClipboard } = useClipboard();
    let showCrypto = reactive([]);
    for (let index in ballots) {
      showCrypto[index] = false;
    }
    const store = useStore();

    let windowUrl = window.location.origin;

    let ballots = store.getters.getBallots;

    let voterElections = store.getters.getVoterElections;
    let currentElection = voterElections.currentElection;
    let noMoreQuestions = reactive({
      val: true,
    });
    axios({
      method: "get",
      url: "/api/elections/" + currentElection + "/questions/allowed",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      noMoreQuestions.val = !Object.values(res.data).includes(false);
    });

    return {
      ballots,
      toClipboard,
      showCrypto,
      windowUrl,
      noMoreQuestions,
    };
  },

  methods: {
    backToQuestions() {
      this.$router.replace("/votingQuestions");
    },
    forwardToFinished() {
      this.$router.replace("/votingElections");
    },
    async copyToClipboard(jsonDump) {
      try {
        await this.toClipboard(jsonDump);
      } catch (e) {
        alert(e);
      }
    },
    async copyAllData() {
      let jsonDump = "";
      jsonDump += "[";
      for (let index in this.ballots) {
        jsonDump += this.ballots[index].json_dump;
        if (index < this.ballots.length - 1) {
          jsonDump += ",";
        }
      }
      jsonDump += "]";
      try {
        await this.toClipboard(jsonDump);
      } catch (e) {
        alert(e);
      }
    },
    sha256TruncateP1(signature) {
      // SHA256, encode with base32 Crockford, truncate to 50 characters, group these
      let hashSignature = this.sha256Encode(signature);
      let upperHashSignature = hashSignature.toUpperCase();
      let truncatedUpperHashSignature = upperHashSignature.substring(0, 50);
      let topLine = truncatedUpperHashSignature.substring(0, 25);
      let separatedTopLine = topLine.match(/.{1,5}/g);
      let spacedTopLine = separatedTopLine.join(" ");

      return spacedTopLine;
    },
    firstPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(0, 25);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
    secondPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(25, 50);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
  },
});
</script>
