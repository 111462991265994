<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- TODO: Cleanup code and remove refences to the previous code template -->
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <div
        class="
          relative
          z-10
          pb-8
          bg-white
          sm:pb-16
          md:pb-20
          lg:max-w-2xl lg:w-full lg:pb-28
          xl:pb-32
        "
      >
        <main
          class="
            mt-10
            mx-auto
            max-w-7xl
            px-4
            sm:mt-12 sm:px-6
            md:mt-16
            lg:mt-20 lg:px-8
            xl:mt-28
          "
        >
          <div class="sm:text-center lg:text-left">
            <h1
              class="
                text-4xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block xl:inline">Voting Finished</span>
              {{ " " }}
              <!-- <span class="block text-yellow-600 xl:inline"
                >festival voting</span
              > -->
            </h1>
            <p
              class="
                mt-3
                text-base text-gray-500
                sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                md:mt-5 md:text-xl
                lg:mx-0
              "
            >
              Thank you for voting today. Please fill in our short survey to
              help us improve your voting experience in the future.
            </p>
            <div
              class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
            >
              <div class="rounded-md shadow">
                <a
                  href="https://forms.gle/NMrVjKJuUPJjC2kf8"
                  target="_blank"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-yellow-600
                    hover:bg-yellow-700
                    md:py-4 md:text-lg md:px-10
                  "
                >
                  Fill in our survey
                </a>
              </div>
              <!--
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#/votingElections"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-yellow-700
                    bg-yellow-100
                    hover:bg-yellow-200
                    md:py-4 md:text-lg md:px-10
                  "
                >
                  Back to elections
                </a>
              </div>
-->
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
