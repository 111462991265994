<template>
  <div
    class="
      min-h-full
      flex
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="@/assets/dre-ip_logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Coordinator Login
        </h2>
      </div>
      <!-- this action needs to change to an actual API call -->
      <form class="mt-8 space-y-6" @submit.prevent="submitAdminPassword()">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="password" class="sr-only">Coordinator password</label>
            <input
              id="username"
              name="username"
              type="text"
              autocomplete="username"
              required=""
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Username"
              v-model="adminUsername"
            />
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="password"
              required=""
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Password"
              v-model="adminPassword"
            />
          </div>
        </div>
        <div>
          <button
            v-if="adminUsername && adminPassword"
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Sign in
          </button>
          <button
            v-else
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              opacity-50
              cursor-not-allowed
            "
            disabled
          >
            Sign in
          </button>
          <GDialog max-width="500" v-model="hasError">
            <div style="color: #000">
              <div style="padding: 20px">
                <h1
                  class="
                    text-base text-indigo-600
                    font-semibold
                    tracking-wide
                    uppercase
                    pt-2
                  "
                >
                  {{ errorTitle }}
                </h1>

                <h3 class="text-base tracking-wide pt-2 pb-2">
                  <p>
                    {{ errorDescription }}
                  </p>
                </h3>
              </div>
            </div>
          </GDialog>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "CoordinatorLogin",

  components: {
    GDialog,
  },

  data() {
    return {
      adminPassword: "",
      recaptchaToken: "",
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },

  methods: {
    submitAdminPassword() {
      axios({
        method: "post",
        url: "/api/auth/admin",
        data: {
          username: this.adminUsername,
          password: this.adminPassword,
        },
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {
          this.$store.commit("updateLoginState", "Admin");
          this.$router.replace("/electionManagement");
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.errorTitle = "Invalid Credentials";
            this.errorDescription =
              "No admin found with the provided username and password combination";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
    },
    recaptchaVerified(response) {
      this.recaptchaToken = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.recaptchaToken = "";
    },
  },
});
</script>
