<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div
    class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex flex-col space-y-6"
  >
    <div class="lg:text-center">
      <h1
        class="
          text-base text-indigo-600
          font-semibold
          tracking-wide
          uppercase
          pt-10
        "
      >
        Voting Page
      </h1>
      <h3 class="text-base tracking-wide pt-2 pb-5">
        Please either cancel or confirm your votes
      </h3>
    </div>
    <div>
      <ul id="ballots">
        <li v-for="(ballot, i) in ballots" :key="ballot.ballot_id">
          <div class="container mx-auto pb-10">
            <div
              role="listitem"
              class="
                bg-white
                cursor-pointer
                shadow
                rounded-lg
                p-8
                relative
                z-30
              "
            >
              <h1
                class="
                  text-base text-indigo-600
                  font-semibold
                  tracking-wide
                  uppercase
                  pt-5
                  mb-10
                "
              >
                Question: {{ ballot.question_description }}
              </h1>
              <div
                class="flex items-center text-gray-800 text-sm font-bold pb-2"
              >
                Your answer: {{ ballot.candidate }}
              </div>
              <div class="flex justify-center mb-10">
                <div>
                  <div class="form-check">
                    <input
                      class="
                        form-check-input
                        appearance-none
                        rounded-full
                        h-4
                        w-4
                        border border-gray-300
                        bg-white
                        checked:bg-blue-600 checked:border-blue-600
                        focus:outline-none
                        transition
                        duration-200
                        mt-1
                        align-top
                        bg-no-repeat bg-center bg-contain
                        float-left
                        mr-2
                        cursor-pointer
                      "
                      type="radio"
                      :name="i"
                      :id="i + 'cancel'"
                      value="cancel"
                      v-model="ballotSelections[i]"
                    />
                    <label
                      class="form-check-label inline-block text-gray-800"
                      :for="i + 'cancel'"
                    >
                      Cancel
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="
                        form-check-input
                        appearance-none
                        rounded-full
                        h-4
                        w-4
                        border border-gray-300
                        bg-white
                        checked:bg-blue-600 checked:border-blue-600
                        focus:outline-none
                        transition
                        duration-200
                        mt-1
                        align-top
                        bg-no-repeat bg-center bg-contain
                        float-left
                        mr-2
                        cursor-pointer
                      "
                      type="radio"
                      :name="i"
                      :id="i + 'confirm'"
                      value="confirm"
                      v-model="ballotSelections[i]"
                      checked
                    />
                    <label
                      class="form-check-label inline-block text-gray-800"
                      :for="i + 'confirm'"
                    >
                      Confirm
                    </label>
                  </div>
                </div>
              </div>
              <GDialog v-model="showCrypto[i]" max-width="500">
                <div style="color: #000">
                  <div style="padding: 20px">
                    <h1
                      class="
                        text-base text-indigo-600
                        font-semibold
                        tracking-wide
                        uppercase
                        pt-10
                      "
                    >
                      How to verify your vote
                    </h1>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Election ID:
                      <p class="font-mono">{{ ballot.election_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Question ID:
                      <p class="font-mono">{{ ballot.question_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Ballot ID:
                      <p class="font-mono">{{ ballot.ballot_id }}</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2">
                      State:
                      <p class="font-mono">In Progress</p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      Confirmation Code:
                      <p class="font-mono">
                        {{ firstPart(ballot.confirmation_code) }}
                      </p>
                      <p class="font-mono">
                        {{ secondPart(ballot.confirmation_code) }}
                      </p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      <p>
                        In order to verify your vote, take a copy of the
                        election ID, question ID, ballot ID and confirmation
                        code above. Then, head over to the public bulletin
                        board, enter the IDs to find your ballot and check the
                        corresponding confirmation code matches. You can access
                        the bulletin board by clicking on
                        <router-link
                          to="/verification"
                          target="_blank"
                          style="color: #0070d2"
                          >{{ windowUrl + "/#/verification" }}</router-link
                        >
                        (it will open in a new tab).
                      </p>
                    </h3>
                    <h3 class="text-base tracking-wide pt-2 pb-2">
                      <p>
                        By checking this, you are confirming that your ballot
                        has been recorded as cast, which is one of the three
                        elements of end-to-end verifiability that makes this
                        system provably trustworthy. If you would like to learn
                        more about this voting system's guarantees of trust,
                        speak to your local vote administrator.
                      </p>
                    </h3>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      padding: 10px 20px;
                      border-top: 1px solid rgba(0, 0, 0, 0.12);
                    "
                  >
                    <button
                      class="
                        right-0
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      @click="showCrypto[i] = false"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </GDialog>
              <div
                class="
                  flex
                  items-center
                  bg-white
                  text-gray text-sm
                  hover:text-blue-500
                  font-bold
                "
                role="alert"
                @click="showCrypto[i] = true"
              >
                <svg
                  class="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
                  />
                </svg>
                <p>(Optional) Verify your vote</p>
                <!-- <p>
                  Please click here if you would like to verify your ballot.
                </p> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="flex justify-end">
        <button
          @click="sendBallotChoices"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
        >
          Submit
        </button>
      </div>
    </div>
    <!--    <div class="container mx-auto pb-10">
      <div
        role="listitem"
        class="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30"
      >
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-5
            mb-10
          "
        >
          What would you like to do?
        </h1>
        <h3 class="text-base tracking-wide pt-2">
          Ballot ID: {{ this.ballotId }}
        </h3>
        <h3 class="text-base tracking-wide pt-2 pb-10">
          Receipt: {{ this.receipt }}
        </h3>
        <div class="flex justify-center mx-auto space-x-6">
          <button
            @click="backToQuestions"
            class="
              bg-red-600
              hover:bg-red-700
              md:mt-0
              mt-4
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
          >
            Cancel
          </button>

          <button
            @click="forwardToFinished"
            class="
              bg-green-600
              hover:bg-green-700
              md:mt-0
              mt-4
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </div> -->
    <div></div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "../../store";
import axios from "axios";
import crypto from "crypto";
import base32 from "base32";
import useClipboard from "vue-clipboard3";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Voting Page",

  components: {
    GDialog,
  },
  data() {
    return {
      dialogState: false,
      windowUrl: window.location.origin,
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  setup() {
    const store = useStore();
    let ballots = store.getters.getBallots;
    let ballotSelections = [];
    for (let index in ballots) {
      ballotSelections[index] = "confirm";
    }
    const { toClipboard } = useClipboard();
    let showCrypto = reactive([]);
    for (let index in ballots) {
      showCrypto[index] = false;
    }
    return {
      ballots,
      ballotSelections,
      toClipboard,
      showCrypto,
    };
  },
  created() {
    this.receipt = "8A14 18B4 B98B 6C9B 8F14 21F0 97A4 50B7";
    this.ballotId = 1;
    this.questions = [
      {
        questionName: "Who is the best Puja?",
        candidates: [
          {
            id: 1,
            name: "Candidate 1",
          },
          {
            id: 2,
            name: "Candidate 2",
          },
          {
            id: 3,
            name: "Candidate 3",
          },
        ],
      },
      {
        questionName: "Who is the best green Puja?",
        candidates: [
          {
            id: 1,
            name: "Candidate 4",
          },
          {
            id: 2,
            name: "Candidate 5",
          },
          {
            id: 3,
            name: "Candidate 1",
          },
        ],
      },
    ];
  },

  methods: {
    firstPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(0, 25);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
    secondPart(confirmationCode) {
      let ccSubstring = confirmationCode.substring(25, 50);
      let separatedCCSubstring = ccSubstring.match(/.{1,5}/g);
      let spacedCCSubstring = separatedCCSubstring.join(" ");
      return spacedCCSubstring;
    },
    backToQuestions() {
      this.$router.replace("/votingQuestions");
    },
    forwardToFinished() {
      this.$router.replace("/votingFinished");
    },
    async sendBallotChoices() {
      let voterElections = this.$store.getters.getVoterElections;
      let currentElection = voterElections.currentElection;
      // get the data
      let ballots = this.ballots; // [{"ballot_id", "other_id", ...}, {...}]
      let ballotSelections = this.ballotSelections; // ["cancel", "confirm", "cancel", ...]
      // convert it to workable JSON format, using filtering
      let audits = [];
      let confirms = [];
      for (let index in ballots) {
        if (ballotSelections[index] == "cancel") {
          audits.push({
            ballot_id: ballots[index].ballot_id,
            question_id: ballots[index].question_id,
            signature: ballots[index].signature,
          });
        } else if (ballotSelections[index] == "confirm") {
          confirms.push({
            ballot_id: ballots[index].ballot_id,
            question_id: ballots[index].question_id,
            signature: ballots[index].signature,
          });
        }
      }

      // send two requests, one for audits, one for confirms
      let auditsRes = [];
      let confirmsRes = [];
      await axios({
        method: "post",
        url: "/api/elections/" + currentElection + "/votes/audit",
        data: audits,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          auditsRes = res.data;
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.errorTitle = "Not Found or Unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });

      await axios({
        method: "post",
        url: "/api/elections/" + currentElection + "/votes/confirm",
        data: confirms,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          confirmsRes = res.data;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errorTitle = "Cannot Confirm Ballot";
            this.errorDescription =
              "You are not eligible to confirm one or more of the ballots you have tried to confirm.";
          } else if (err.response.status == 404) {
            this.errorTitle = "Not Found or Unauthorised";
            this.errorDescription =
              "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
          this.hasError = true;
        });
      // store responses in the store for the next page

      let auditsPointer = 0;
      let confirmsPointer = 0;
      for (let index in ballots) {
        if (ballotSelections[index] == "cancel") {
          ballots[index].ballot_id = auditsRes[auditsPointer].ballot_id;
          ballots[index].question_id = auditsRes[auditsPointer].question_id;
          ballots[index].election_id = auditsRes[auditsPointer].election_id;
          ballots[index].state = auditsRes[auditsPointer].state;
          ballots[index].confirmation_code =
            auditsRes[auditsPointer].confirmation_code;
          ballots[index].is_confirmed = false;
          ballots[index].signature = auditsRes[auditsPointer].signature;
          ballots[index].candidate = auditsRes[auditsPointer].candidate;
          auditsPointer += 1;
        } else if (ballotSelections[index] == "confirm") {
          ballots[index].ballot_id = confirmsRes[confirmsPointer].ballot_id;
          ballots[index].question_id = confirmsRes[confirmsPointer].question_id;
          ballots[index].election_id = confirmsRes[confirmsPointer].election_id;
          ballots[index].state = confirmsRes[confirmsPointer].state;
          ballots[index].confirmation_code =
            confirmsRes[confirmsPointer].confirmation_code;
          ballots[index].is_confirmed = true;
          ballots[index].signature = confirmsRes[confirmsPointer].signature;
          confirmsPointer += 1;
        }
      }

      this.$store.commit("updateBallots", ballots);
      // route to next page
      this.$router.replace("/votingAfterStageTwo");
    },
    sha256TruncateP1(signature) {
      // SHA256, encode with base32 Crockford, truncate to 50 characters, group these
      let hashSignature = this.sha256Encode(signature);
      let upperHashSignature = hashSignature.toUpperCase();
      let truncatedUpperHashSignature = upperHashSignature.substring(0, 50);
      let topLine = truncatedUpperHashSignature.substring(0, 25);
      let separatedTopLine = topLine.match(/.{1,5}/g);
      let spacedTopLine = separatedTopLine.join(" ");

      return spacedTopLine;
    },
    sha256TruncateP2(signature) {
      // SHA256, encode with base32 Crockford, truncate to 50 characters, group these
      let hashSignature = this.sha256Encode(signature);
      let upperHashSignature = hashSignature.toUpperCase();
      let truncatedUpperHashSignature = upperHashSignature.substring(0, 50);
      let bottomLine = truncatedUpperHashSignature.substring(25, 50);
      let separatedBottomLine = bottomLine.match(/.{1,5}/g);
      let spacedBottomLine = separatedBottomLine.join(" ");

      return spacedBottomLine;
    },
    sha256Encode(signature) {
      var hash = crypto.createHash("sha256");
      hash.digest = (function (digest) {
        return function () {
          return base32.encode(digest.call(this, "binary"));
        };
      })(hash.digest);
      return hash.update(signature).digest();
    },
    async copyToClipboard(jsonDump) {
      try {
        await this.toClipboard(jsonDump);
      } catch (e) {
        alert(e);
      }
    },
    async copyAllData() {
      let jsonDump = "";
      jsonDump += "[";
      for (let index in this.ballots) {
        jsonDump += this.ballots[index].json_dump;
        if (index < this.ballots.length - 1) {
          jsonDump += ",";
        }
      }
      jsonDump += "]";
      try {
        await this.toClipboard(jsonDump);
      } catch (e) {
        alert(e);
      }
    },
    updateShowCrypto(index, val) {
      this.showCrypto[index] = val;
    },
    downloadBallotData(ballotId, jsonDump) {
      let text = jsonDump;
      let filename = ballotId + ".json";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
});
</script>
