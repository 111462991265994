<template>
  <div>
    <!-- OPTIONAL TODO: Create button to edit -->
    <div
      class="
        bg-white
        relative
        block
        w-64
        mx-auto
        px-3
        py-2
        border border-gray-200
        rounded-md
        sm:text-sm
      "
    >
      <div class="flow-root">
        <div class="float-left">{{ candidate }}</div>
        <!-- TODO: replace x letter with FAS fa fa-times class -->
        <button
          @click="deleteCandidate(candidate)"
          class="float-right text-red-600 font-bold"
        >
          x
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Candidate",
  props: {
    candidate: Object,
  },
  methods: {
    deleteCandidate(candidate) {
      this.$emit("delete-candidate", candidate);
    },
  },
});
</script>
