<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div
    class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex flex-col space-y-6"
  >
    <div class="lg:text-center">
      <h1
        class="
          text-base text-indigo-600
          font-semibold
          tracking-wide
          uppercase
          pt-10
        "
      >
        Voting Page
      </h1>
      <h3 class="text-base tracking-wide pt-2 pb-10">
        Please select your groups
      </h3>
    </div>
    <div>
      <ul id="elections">
        <li v-for="electorate in getElectorates()" :key="electorate.name">
          <div class="container mx-auto pb-10">
            <div
              role="listitem"
              class="
                bg-white
                cursor-pointer
                shadow
                rounded-lg
                p-8
                relative
                z-30
              "
            >
              <div class="mx-auto">
                <h2 class="text-2xl font-semibold leading-6 text-gray-800 mb-5">
                  {{ electorate.name }}
                </h2>

                <div class="flex justify-center">
                  <div>
                    <div v-if="electorate.is_mutex">
                      <div v-for="group in electorate.groups" :key="group">
                        <div class="form-check mb-1">
                          <input
                            class="
                              form-check-input
                              appearance-none
                              h-4
                              w-4
                              border border-gray-300
                              rounded-full
                              bg-white
                              checked:bg-blue-600 checked:border-blue-600
                              focus:outline-none
                              transition
                              duration-200
                              mt-1
                              align-top
                              bg-no-repeat bg-center bg-contain
                              float-left
                              mr-2
                              cursor-pointer
                            "
                            type="radio"
                            :id="electorate.name + '-' + group"
                            :value="group"
                            v-model="groupSelections[electorate.name]"
                          />
                          <label
                            class="form-check-label text-gray-800"
                            :for="electorate.name + '-' + group"
                          >
                            {{ group }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="group in electorate.groups" :key="group">
                        <div class="form-check mb-1">
                          <input
                            class="
                              form-check-input
                              appearance-none
                              h-4
                              w-4
                              border border-gray-300
                              rounded-sm
                              bg-white
                              checked:bg-blue-600 checked:border-blue-600
                              focus:outline-none
                              transition
                              duration-200
                              mt-1
                              align-top
                              bg-no-repeat bg-center bg-contain
                              float-left
                              mr-2
                              cursor-pointer
                            "
                            type="checkbox"
                            :id="group"
                            :value="group"
                            v-model="groupSelections[electorate.name]"
                          />
                          <label
                            class="form-check-label text-gray-800"
                            :for="group"
                          >
                            {{ group }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="flex justify-end">
        <button
          @click="sendGroupChoices"
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
          "
          v-if="!Object.values(groupSelections).includes(null)"
        >
          Confirm Groups
        </button>
        <button
          class="
            bg-indigo-600
            hover:bg-indigo-700
            md:mt-0
            mt-4
            text-white
            font-bold
            py-2
            px-4
            rounded
            mx-auto
            opacity-50
            cursor-not-allowed
          "
          v-else
          disabled
        >
          Confirm Groups
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import axios from "axios";
import { useStore } from "../../store";
import { useRouter } from "../../router";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Voting Page",

  components: {
    GDialog,
  },

  data() {
    return {
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    let voterElections = store.getters.getVoterElections;
    let currentElection = voterElections.currentElection;
    let groupSelections = reactive({});
    // firstly call to check if the user has selected their groups already for this election
    axios({
      method: "get",
      url: "/api/elections/" + currentElection + "/join",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.data) {
          router.replace("/votingQuestions");
        } else {
          axios({
            method: "get",
            url: "/api/elections/" + currentElection,
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => {
              let electionData = res.data;
              voterElections.elections[currentElection]["electorates"] =
                electionData["electorates"];
              if (Object.keys(electionData["electorates"]).length === 0) {
                axios({
                  method: "post",
                  url: "/api/elections/" + currentElection + "/join",
                  data: {},
                  headers: { "Content-Type": "application/json" },
                }).then(() => {
                  router.replace("/votingQuestions");
                });
              } else {
                console.log(
                  "electorates is ",
                  JSON.stringify(electionData["electorates"])
                );
                store.commit("updateVoterElections", voterElections);
                // then, if this is not the case, get the groups so the user can make their choices
                // get the electorates from the store to intialise the choices dictionary
                let electorates =
                  voterElections.elections[currentElection]["electorates"];
                for (let electorate in electorates) {
                  if (!electorates[electorate].is_mutex)
                    groupSelections[electorate] = [];
                  else groupSelections[electorate] = null;
                }
                console.log(Object.values(groupSelections).includes(null));
              }
            })
            .catch((err) => {
              if (err.response.status == 404) {
                this.errorTitle = "Not found or unauthorised";
                this.errorDescription =
                  "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
              } else {
                this.errorTitle = "Unknown Error";
                this.errorDescription =
                  "Something went wrong. Please try again. If the issue persists, contact an administrator.";
              }
              this.hasError = true;
            });
        }
      })
      .catch((err) => {
        if (err.response.status == 404) {
          this.errorTitle = "Not found or unauthorised";
          this.errorDescription =
            "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
        } else {
          this.errorTitle = "Unknown Error";
          this.errorDescription =
            "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        }
        this.hasError = true;
      });
    return {
      groupSelections,
    };
  },
  methods: {
    getElectorates() {
      // TODO: Store group choices - navigation
      let voterElections = this.$store.getters.getVoterElections;
      let currentElection = voterElections.currentElection;
      return voterElections.elections[currentElection]["electorates"];
    },
    sendGroupChoices() {
      // this.groupSelections holds the group selections
      let selections = this.groupSelections;
      // convert any mutex electorate's group value to an array containing it
      for (const [key, value] of Object.entries(selections)) {
        if (!Array.isArray(value)) {
          if (value == null) {
            selections[key] = [];
          } else {
            selections[key] = [value];
          }
        }
      }
      // send axios request
      let voterElections = this.$store.getters.getVoterElections;
      let currentElection = voterElections.currentElection;
      axios({
        method: "post",
        url: "/api/elections/" + currentElection + "/join",
        data: selections,
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {
          this.$router.replace("/votingQuestions");
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.errorTitle = "Already Joined The Election";
            this.errorDescription = "This election has already been joined.";
          } else if (err.response.status == 404) {
            this.errorTitle = "Electorate Not Found or Unauthorised";
            this.errorDescription =
              "The submitted electorate does not exist or user unauthenticated.";
          } else if (err.response.status == 422) {
            this.errorTitle = "Violation of Mutual Exclusivity Constraints";
            this.errorDescription =
              "There are some groups you have selected for which you cannot be a part of both.";
          } else {
            this.errorTitle = "Unknown Error";
            this.errorDescription =
              "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          }
        });
    },
  },
});
</script>
