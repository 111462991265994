<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- TODO: Cleanup code and remove refences to the previous code template -->
  <Disclosure as="nav" class="bg-gray-900" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-gray-400
              hover:text-white hover:bg-gray-700
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
            "
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="
            flex-1 flex
            items-center
            justify-center
            sm:items-stretch sm:justify-start
          "
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              src="@/assets/dre-ip_logo.png"
              alt="Workflow"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="@/assets/dre-ip_logo.png"
              alt="Workflow"
            />
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div
              class="flex space-x-4"
              v-if="$store.getters.getLoginState == 'Voter'"
            >
              <a
                v-for="item in navigationLoggedInVoter"
                :key="item.name"
                :href="item.href"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'px-3 py-2 rounded-md text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</a
              >
            </div>
            <div
              class="flex space-x-4"
              v-else-if="$store.getters.getLoginState == 'Admin'"
            >
              <a
                v-for="item in navigationLoggedInCoordinator"
                :key="item.name"
                :href="item.href"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'px-3 py-2 rounded-md text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</a
              >
            </div>
            <div class="flex space-x-4" v-else>
              <a
                v-for="item in navigationLoggedOut"
                :key="item.name"
                :href="item.href"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'px-3 py-2 rounded-md text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div
        class="px-2 pt-2 pb-3 space-y-1"
        v-if="$store.getters.getLoginState == 'Voter'"
      >
        <DisclosureButton
          v-for="item in navigationLoggedInVoter"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
      <div
        class="px-2 pt-2 pb-3 space-y-1"
        v-else-if="$store.getters.getLoginState == 'Admin'"
      >
        <DisclosureButton
          v-for="item in navigationLoggedInCoordinator"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
      <div class="px-2 pt-2 pb-3 space-y-1" v-else>
        <DisclosureButton
          v-for="item in navigationLoggedOut"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { useStore } from "../store";
import axios from "axios";

const navigationLoggedOut = [
  { name: "Homepage", href: "#", current: false },
  { name: "Vote", href: "#/login", current: false },
  { name: "Bulletin Board", href: "#/verification", current: false },
  { name: "Tally", href: "#/tally", current: false },
  {
    name: "Contact",
    href: "mailto:indiaevotingtrial@gmail.com",
    current: false,
  },
  { name: "Coordinator", href: "#/coordinatorLogin", current: false },
];

const navigationLoggedInVoter = [
  { name: "Homepage", href: "#", current: false },
  { name: "Vote", href: "#/votingElections", current: false },
  { name: "Bulletin Board", href: "#/verification", current: false },
  { name: "Tally", href: "#/tally", current: false },
  {
    name: "Contact",
    href: "mailto:indiaevotingtrial@gmail.com",
    current: false,
  },
  { name: "Coordinator", href: "#/coordinatorLogin", current: false },
  { name: "Logout", href: "#/logout", current: false },
];

const navigationLoggedInCoordinator = [
  { name: "Homepage", href: "#", current: false },
  { name: "Vote", href: "#/login", current: false },
  { name: "Bulletin Board", href: "#/verification", current: false },
  { name: "Tally", href: "#/tally", current: false },
  {
    name: "Contact",
    href: "mailto:indiaevotingtrial@gmail.com",
    current: false,
  },
  { name: "Coordinator", href: "#/electionManagement", current: false },
  { name: "Logout", href: "#/logout", current: false },
];

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    const store = useStore();
    axios({
      method: "get",
      url: "/api/auth/check",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      store.commit("updateLoginState", res.data);
    });

    return {
      navigationLoggedInVoter,
      navigationLoggedInCoordinator,
      navigationLoggedOut,
    };
  },
  name: "Navbar",
});
</script>
