// store.ts
// import { ListClassKey } from "@mui/material";
import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

type ElectoratesType = {
  name: string;
  is_mutex: boolean;
  groups: string[];
};

type QuestionsType = {
  description: string;
  constraints: { [name: string]: string[] };
  candidates: string[];
};

// Voter Election Types

type ElectionsType = {
  id: number;
  name: string;
  electorates: { [name: string]: ElectoratesType };
  questions: { [id: number]: QuestionsType };
  groupsChoices: string[];
  questionsChoices: { [question: string]: string };
};

type ShowElectionsQuestionsType = {
  description: string;
  constraints: { [name: string]: string[] };
  candidates: string[];
};

type ShowElectionsType = {
  id: number;
  name: string;
  state: string;
  start_time: string;
  end_time: string;
  electorates: { [name: string]: ElectoratesType };
  questions: ShowElectionsQuestionsType[];
};

type VoterElectionsType = {
  elections: { [id: number]: ElectionsType };
  currentElection: number;
};

type BallotType = {
  ballot_id: number;
  question_id: number;
  election_id: number;
  question_description: string;
  state: string;
  confirmation_code: string;
  signature: string;
  is_confirmed: boolean;
  candidate: string;
};

export interface State {
  login: string;
  electorates: { [name: string]: ElectoratesType };
  questions: QuestionsType[];
  ballots: BallotType[];
  voterElections: VoterElectionsType;
  showElectionsData: { [id: number]: ShowElectionsType };
  archivedElectionsData: { [id: number]: ShowElectionsType };
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    login: "",
    electorates: {},
    questions: [],
    showElectionsData: {},
    archivedElectionsData: {},
    ballots: [],
    voterElections: {
      elections: {},
      currentElection: 0,
    },
  },
  mutations: {
    updateLoginState(state, loginState) {
      state.login = loginState;
    },
    updateElectorates(state, newElectoratesList) {
      state.electorates = newElectoratesList;
    },
    updateQuestions(state, newQuestionsList) {
      state.questions = newQuestionsList;
    },
    updateVoterElections(state, newVoterElections) {
      state.voterElections = newVoterElections;
    },
    updateBallots(state, newBallots) {
      state.ballots = newBallots;
    },
    updateShowElectionsData(state, showElectionsData) {
      state.showElectionsData = showElectionsData;
    },
    updateArchivedElectionsData(state, showElectionsData) {
      state.archivedElectionsData = showElectionsData;
    },
  },
  getters: {
    getLoginState(state) {
      return state.login;
    },
    getQuestions(state) {
      return state.questions;
    },
    getElectorates(state) {
      return state.electorates;
    },
    getVoterElections(state) {
      return state.voterElections;
    },
    getBallots(state) {
      return state.ballots;
    },
    getShowElectionsData(state) {
      return state.showElectionsData;
    },
    getArchivedElectionsData(state) {
      return state.archivedElectionsData;
    },
  },
});

// define your own `useStore` composition function
export function useStore(): typeof store {
  return baseUseStore(key);
}
