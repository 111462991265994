<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- TODO: Cleanup code and remove refences to the previous code template -->
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <div
        class="
          relative
          z-10
          pb-8
          bg-white
          sm:pb-16
          md:pb-20
          lg:max-w-2xl lg:w-full lg:pb-28
          xl:pb-32
        "
      >
        <svg
          class="
            hidden
            lg:block
            absolute
            right-0
            inset-y-0
            h-full
            w-48
            text-white
            transform
            translate-x-1/2
          "
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <main
          class="
            mt-10
            mx-auto
            max-w-7xl
            px-4
            sm:mt-12 sm:px-6
            md:mt-16
            lg:mt-20 lg:px-8
            xl:mt-28
          "
        >
          <div class="sm:text-center lg:text-left">
            <h1
              class="
                text-4xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block xl:inline"
                >New Town Sharad Samman 2022-"Best Overall"</span
              >
              {{ " " }}
              <br />
              <span class="block text-yellow-600 xl:inline"
                >Public e-Voting System</span
              >
            </h1>
            <p
              class="
                mt-3
                text-base text-gray-500
                sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                md:mt-5 md:text-xl
                lg:mx-0
              "
            >
              {{ description }}
            </p>
            <div
              class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
            >
              <div
                class="rounded-md shadow"
                v-if="$store.getters.getLoginState == 'Voter'"
              >
                <a
                  href="#/votingElections"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-yellow-600
                    hover:bg-yellow-700
                    md:py-4 md:text-lg md:px-10
                  "
                >
                  Start voting
                </a>
              </div>
              <div class="rounded-md shadow" v-else>
                <a
                  href="#/login"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-yellow-600
                    hover:bg-yellow-700
                    md:py-4 md:text-lg md:px-10
                  "
                >
                  Start Voting
                </a>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#/tally"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-yellow-700
                    bg-yellow-100
                    hover:bg-yellow-200
                    md:py-4 md:text-lg md:px-10
                  "
                >
                  View Tally
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="2xl:absolute 2xl:inset-y-0 2xl:right-0 2xl:w-1/2">
      <img
        class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        src="../assets/homepage.jpg"
        alt=""
      />
      <!-- <img
        class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        src="https://images.unsplash.com/photo-1499123785106-343e69e68db1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1474&q=80"
        alt=""
      /> -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  data() {
    return {
      description:
        "Vote for your most loved Durga Puja in New Town Kolkata (Block / Housing Category) " +
        "and make your Puja win New Town Sharad Samman 2022 ",
    };
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
});
</script>
