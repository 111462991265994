<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div>
    <p
      class="
        mt-2
        text-3xl
        leading-8
        pb-5
        font-extrabold
        tracking-tight
        text-gray-900
        sm:text-4xl
      "
    >
      Manage Elections
    </p>

    <!-- TODO: Change this.elections with store values -->
    <div class="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30">
      <div
        v-for="(election, idx) in $store.getters.getShowElectionsData"
        :key="idx"
      >
        <div
          class="
            p-4
            mb-3
            items-center
            bg-gray-200
            shadow
            rounded-lg
            cursor-move
            border border-white
          "
        >
          <div class="flex justify-between mb-4">
            <div class="items-center">
              <p
                class="ml-2 text-gray-700 font-semibold font-sans tracking-wide"
              >
                {{ election.name }}
              </p>
            </div>
            <div class="flex">
              <button
                title="Download Election"
                aria-label="Download Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-teal-500
                  hover:text-teal-600
                "
                v-on:click="downloadElection(election.id)"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                  <polyline points="7 11 12 16 17 11" />
                  <line x1="12" y1="4" x2="12" y2="16" />
                </svg>
              </button>
              <button
                title="Edit Election"
                aria-label="Edit Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-teal-500
                  hover:text-teal-600
                "
                v-on:click="changeEditElection(election.id)"
                v-show="
                  election.state == 'Draft' ||
                  (election.state == 'Published' &&
                    new Date(election.start_time) >= new Date())
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
              </button>
              <button
                title="Publish Election"
                aria-label="Publish Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-teal-500
                  hover:text-teal-600
                "
                v-on:click="publishElection(election.id)"
                v-show="election.state == 'Draft'"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="22" y1="2" x2="11" y2="13" />
                  <polygon points="22 2 15 22 11 13 2 9 22 2" />
                </svg>
              </button>
              <button
                title="Archive Election"
                aria-label="Archive Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-teal-500
                  hover:text-teal-600
                "
                v-on:click="archiveElection(election.id)"
                v-show="
                  election.state == 'Draft' || election.state == 'Published'
                "
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="21 8 21 21 3 21 3 8" />
                  <rect x="1" y="3" width="22" height="5" />
                  <line x1="10" y1="12" x2="14" y2="12" />
                </svg>
              </button>
              <button
                title="Delete Election"
                aria-label="Delete Election"
                class="
                  action-button
                  p-1
                  focus:outline-none focus:shadow-outline
                  text-teal-500
                  hover:text-teal-600
                "
                v-on:click="deleteElection(election.id)"
                v-show="
                  election.state == 'Draft' || election.state == 'Archived'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          <!-- TODO: Align these items to left and use a bold tag on the name -->
          <div class="mb-2">
            <div>{{ "Election state: " + election.state }}</div>
            <div>{{ "Election ID: " + election.id }}</div>
            <div>{{ "Start time: " + new Date(election.start_time) }}</div>
            <div>{{ "End time: " + new Date(election.end_time) }}</div>
          </div>

          <!-- Election - dopdown for editing data -->
          <div v-show="editElection[election.id]">
            <br />
            <UpdateElection :electionID="election.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "@vue/runtime-core";
import UpdateElection from "../election_management/UpdateElection.vue";
import { useStore } from "../../store";
import axios from "axios";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Manage Elections",
  components: {
    UpdateElection,
    GDialog,
  },

  data() {
    return {
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  setup() {
    const store = useStore();
    var showArchived = false;
    var electionList = [];
    var elections = [];
    var editElection = reactive({});

    axios({
      method: "get",
      url: "/api/elections",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        electionList = res.data;

        (async () => {
          // Get more data about particular election and store it
          elections = {};
          for (let election in electionList) {
            await axios({
              method: "get",
              url: "/api/elections/" + electionList[election].id,
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => {
                let electionData = res.data;

                // Create list of booleans - true if we edit election, false otherwise
                editElection[electionData.id] = false;

                let electionQuestions = [];
                for (let electionKeys in electionData.questions) {
                  let questionData = electionData.questions[electionKeys];
                  electionQuestions.push({
                    description: questionData.description,
                    constraints: questionData.constraints,
                    candidates: questionData.candidates,
                  });
                }

                elections[electionList[election].id] = {
                  id: electionData.id,
                  name: electionData.name,
                  state: electionData.state,
                  start_time: electionData.start_time,
                  end_time: electionData.end_time,
                  electorates: electionData.electorates,
                  questions: electionQuestions,
                };
              })
              .catch((err) => {
                if (err.response.status == 404) {
                  this.errorTitle = "Not found or unauthorised";
                  this.errorDescription =
                    "Requested resource was not found or user unauthenticated. Please ensure you are logged in.";
                } else {
                  this.errorTitle = "Unknown Error";
                  this.errorDescription =
                    "Something went wrong. Please try again. If the issue persists, contact an administrator.";
                }
                this.hasError = true;
              });
          }

          store.commit("updateShowElectionsData", elections);
        })();
      })
      .catch((err) => {
        this.errorTitle = "Unknown Error";
        this.errorDescription =
          "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        this.hasError = true;
      });
    return {
      editElection,
      showArchived,
    };
  },
  methods: {
    downloadElection(electionID) {
      let election = this.$store.getters.getShowElectionsData[electionID];
      let text = JSON.stringify(election);
      let filename = electionID + ".json";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    changeEditElection(electionID) {
      this.editElection[electionID] = !this.editElection[electionID];
    },
    publishElection(electionID) {
      if (confirm("Are you sure you would like to publish this election?")) {
        axios({
          method: "post",
          url: "/api/elections/" + electionID + "/publish",
          headers: { "Content-Type": "application/json" },
        })
          .then(() => {
            alert("Successfully published election");
            window.location.reload(true);
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.errorTitle = "Bad election state";
              this.errorDescription = "The election was not in a draft state.";
            } else {
              this.errorTitle = "Unknown Error";
              this.errorDescription =
                "Something went wrong. Please try again. If the issue persists, contact an administrator.";
            }
            this.hasError = true;
          });
        alert("Successfully published election");
        window.location.reload(true);
      }
    },
    archiveElection(electionID) {
      if (confirm("Are you sure you would like to archive this election?")) {
        axios({
          method: "post",
          url: "/api/elections/" + electionID + "/archive",
          headers: { "Content-Type": "application/json" },
        })
          .then(() => {
            alert("Successfully archived election");
            window.location.reload(true);
          })

          .catch((err) => {
            if (err.response.status == 400) {
              this.errorTitle = "Election already archived";
              this.errorDescription = "The election was already archived.";
            } else {
              this.errorTitle = "Unknown Error";
              this.errorDescription =
                "Something went wrong. Please try again. If the issue persists, contact an administrator.";
            }
            this.hasError = true;
          });
      }
    },
    deleteElection(electionID) {
      if (confirm("Are you sure you would like to delete this election?")) {
        axios({
          method: "delete",
          url: "/api/elections/" + electionID,
          headers: { "Content-Type": "application/json" },
        })
          .then(() => {
            alert("Successfully deleted election");
            window.location.reload(true);
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.errorTitle = "Deletion not allowed";
              this.errorDescription =
                "You are not allowed to delete this election.";
            } else {
              this.errorTitle = "Unknown Error";
              this.errorDescription =
                "Something went wrong. Please try again. If the issue persists, contact an administrator.";
            }
            this.hasError = true;
          });
        alert("Successfully deleted election");
        window.location.reload(true);
      }
    },
  },
});
</script>
