<template>
  <div>
    <div class="bg-gray-100 rounded-t-lg flex flex-col space-y-4">
      <Questions
        @update-candidates="updateCandidates"
        @delete-question="deleteQuestion"
        @update-group-constraints="updateGroupConstraints"
        :questions="$store.getters.getQuestions"
      />

      <!-- Add Question button -->
      <div>
        <form @submit.prevent="addNewQuestion">
          <div class="form-control">
            <!-- <label>Question Name</label> -->
            <input
              type="text"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-yellow-500
                focus:border-yellow-500
                focus:z-10
                sm:text-sm
              "
              name="questionName"
              v-model="newQuestion"
              placeholder="Add new question"
            />
          </div>
          <input
            type="submit"
            value="Add Question"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-b-lg
              text-white
              bg-yellow-600
              hover:bg-yellow-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-yellow-500
            "
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import Questions from "./Questions.vue";

export default defineComponent({
  name: "Election Management",
  components: {
    Questions,
  },
  setup() {
    let questions = [];
    let newQuestion = "";
    return {
      questions,
      newQuestion,
    };
  },
  methods: {
    addNewQuestion() {
      if (!this.newQuestion) {
        alert("Please add the question name");
        return;
      }

      let questions = this.$store.getters.getQuestions;

      const newQuestion = {
        description: this.newQuestion,
        constraints: {},
        candidates: [],
      };

      this.newQuestion = "";
      questions = [...questions, newQuestion];

      this.$store.commit("updateQuestions", questions);
    },

    updateCandidates(updateCandidateElement) {
      let questions = this.$store.getters.getQuestions;
      questions[updateCandidateElement.questionIndex].candidates =
        updateCandidateElement.newCandidateList;
      this.$store.commit("updateQuestions", questions);
    },

    deleteQuestion(questionIndex) {
      if (confirm("Are you sure?")) {
        let questions = this.$store.getters.getQuestions;
        questions.splice(questionIndex, 1);
        this.$store.commit("updateQuestions", questions);
      }
    },

    updateGroupConstraints(groupConstraintsElement) {
      var questions = this.$store.getters.getQuestions;
      questions[groupConstraintsElement.questionIndex].constraints =
        groupConstraintsElement.groupConstraints;
      this.$store.commit("updateQuestions", questions);
    },
  },
});
</script>
