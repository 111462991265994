<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div
    class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex flex-col space-y-6"
  >
    <div class="lg:text-center">
      <h1
        class="
          text-base text-indigo-600
          font-semibold
          tracking-wide
          uppercase
          pt-10
        "
      >
        Voting Page
      </h1>
      <h3 class="text-base tracking-wide pt-2 pb-10">
        Please choose from one of the following elections
      </h3>
    </div>
    <div>
      <ul id="elections">
        <li v-for="election in getElectionNames()" :key="election.id">
          <div class="container mx-auto pb-10">
            <div
              role="listitem"
              class="
                bg-white
                cursor-pointer
                shadow
                rounded-lg
                p-8
                relative
                z-30
              "
            >
              <div class="md:flex items-center justify-between">
                <h2 class="text-2xl font-semibold leading-6 text-gray-800">
                  {{ election.name }}
                </h2>
                <Menu as="div" class="relative inline-block text-left">
                  <div>
                    <MenuButton
                      class="
                        bg-indigo-600
                        hover:bg-indigo-700
                        md:mt-0
                        mt-4
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                      "
                      @click="goToGroups(election.id)"
                    >
                      <p class="text-l font-semibold leading-6 text-white-800">
                        Vote Now
                      </p>
                    </MenuButton>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Menu, MenuButton } from "@headlessui/vue";
import axios from "axios";
import { useStore } from "../../store";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Voting Page",

  components: {
    Menu,
    MenuButton,
    GDialog,
  },
  data() {
    return {
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  setup() {
    const store = useStore();
    axios({
      method: "get",
      url: "/api/elections?timing=current",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        let result = res.data;
        // TODO: Store the questions - result[election].questions
        let elections = {};
        for (let election in result) {
          elections[result[election].id] = {
            id: result[election].id,
            name: result[election].name,
            electorates: {},
            questions: {},
            groupsChoices: [],
            questionsChoices: {},
          };
        }

        store.commit("updateVoterElections", {
          currentElection: null,
          elections: elections,
        });
      })
      .catch((err) => {
        this.errorTitle = "Unknown Error";
        this.errorDescription =
          "Something went wrong. Please try again. If the issue persists, contact an administrator.";
        this.hasError = true;
      });

    return {
      store,
    };
  },
  methods: {
    goToGroups(electionID) {
      // Update the current election id
      let voterElections = this.$store.getters.getVoterElections;
      voterElections.currentElection = electionID;
      this.$store.commit("updateVoterElections", voterElections);

      this.$router.replace("/votingGroups");
    },

    getElectionNames() {
      let voterElections = this.$store.getters.getVoterElections;

      let electionNames = [];
      let electionValues = undefined;

      for (let electionNumber in voterElections["elections"]) {
        electionValues = voterElections["elections"][electionNumber];
        electionNames.push({
          id: electionValues["id"],
          name: electionValues["name"],
        });
      }
      return electionNames;
    },
  },
});
</script>
