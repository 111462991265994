<template>
  <GDialog max-width="500" v-model="hasError">
    <div style="color: #000">
      <div style="padding: 20px">
        <h1
          class="
            text-base text-indigo-600
            font-semibold
            tracking-wide
            uppercase
            pt-2
          "
        >
          {{ errorTitle }}
        </h1>

        <h3 class="text-base tracking-wide pt-2 pb-2">
          <p>
            {{ errorDescription }}
          </p>
        </h3>
      </div>
    </div>
  </GDialog>
  <div>
    <p
      class="
        mt-2
        text-3xl
        leading-8
        pb-5
        font-extrabold
        tracking-tight
        text-gray-900
        sm:text-4xl
      "
    >
      Create Election
    </p>

    <!-- START TABS -->
    <div>
      <div class="w-full px-2 sm:px-0">
        <TabGroup>
          <TabList class="flex p-1 space-x-1 bg-indigo-50 rounded-xl">
            <Tab
              :class="[
                'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
              ]"
            >
              <button
                :class="[
                  'bg-indigo-700 w-full py-2.5 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                ]"
                v-on:click="component = 'questionsTab'"
              >
                Add Questions
              </button>
            </Tab>
            <Tab
              :class="[
                'w-full text-sm leading-5 font-medium text-indigo-100 rounded-lg',
              ]"
            >
              <button
                :class="[
                  'bg-indigo-700 w-full py-2.5 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-indigo-50 hover:bg-indigo-600 hover:text-white',
                ]"
                v-on:click="component = 'electoratesTab'"
              >
                Add Electorates
              </button>
            </Tab>
          </TabList>
        </TabGroup>
      </div>
    </div>
    <br />

    <div class="form-control">
      <input
        type="text"
        class="
          appearance-none
          rounded-t-lg
          relative
          block
          w-full
          px-3
          py-2
          border border-gray-300
          placeholder-gray-500
          text-gray-900
          focus:outline-none
          focus:ring-yellow-500
          focus:border-yellow-500
          focus:z-10
          sm:text-sm
        "
        name="electionName"
        v-model="electionName"
        placeholder="Election Name"
      />
    </div>
    <!-- </form>
      </div> -->
    <br />

    <keep-alive>
      <component v-bind:is="component"></component>
    </keep-alive>
    <br />

    <Datepicker
      v-model="date"
      placeholder="Enter start and end dates"
      :minDate="new Date()"
      range
    />

    <select
      v-model="timeZoneOption"
      class="
        border border-gray-300
        text-gray-900 text-sm
        rounded-b-lg
        focus:ring-yellow-500 focus:border-yellow-500
        block
        w-full
        p-2.5
        dark:bg-gray-700
        dark:text-white
        dark:focus:ring-yellow-500
        dark:focus:border-yellow-500
      "
    >
      <option :key="timezone.value" v-for="timezone in loadTimeZoneList()">
        {{ timezone.textContent }}
      </option>
    </select>

    <!-- Checkbox for publishing the election -->
    <div class="flex px-3 py-4">
      <label class="flex items-center">
        <input type="checkbox" name="options" v-model="publishElection" />
        <span class="ml-2 text-sm">Publish Election</span>
      </label>
    </div>

    <input
      type="submit"
      @click="createElection"
      value="Create Election"
      class="
        group
        relative
        w-full
        flex
        justify-center
        py-2
        px-4
        border border-transparent
        text-sm
        font-medium
        rounded-lg
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
      "
    />

    <!-- END TABS -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/runtime-core";

import Questions from "./Questions.vue";
import AddElectoratesTab from "../election_management/AddElectoratesTab.vue";
import AddQuestionsTab from "../election_management/AddQuestionsTab.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import moment from "moment-timezone";
import { GDialog } from "gitart-vue-dialog";

export default defineComponent({
  name: "Election Management",
  components: {
    GDialog,
    Questions,
    Datepicker,
    questionsTab: AddQuestionsTab,
    electoratesTab: AddElectoratesTab,
  },
  setup() {
    const date = ref();
    let electionName = "";
    let publishElection = false;

    // We choose the user's timezone by default
    let userTimeZone = moment.tz.guess();
    let timeZoneOption = `${userTimeZone} (GMT${moment
      .tz(userTimeZone)
      .format("Z")})`;

    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      date.value = [startDate, endDate];
    });

    return {
      electionName,
      date,
      publishElection,
      timeZoneOption,
    };
  },
  data() {
    return {
      // Initialized to an empty list to begin
      questions: [],
      component: "questionsTab",
      hasError: false,
      errorTitle: "",
      errorDescription: "",
    };
  },
  methods: {
    createElection() {
      // There are 595 timezones and we separate them from GMT value by space character
      var electionTimeZone = this.timeZoneOption.substring(
        0,
        this.timeZoneOption.indexOf(" ")
      );
      // need electorates to be an array rather than dictionary
      const electorates = this.$store.getters.getElectorates;
      var electoratesList = [];
      for (let electorate in electorates) {
        electoratesList.push(electorates[electorate]);
      }
      const questions = this.$store.getters.getQuestions;
      // now all data are available, construct a suitable axios request
      const data = {
        name: this.electionName,
        start_time: this.getUTCFromTimezone(this.date[0], electionTimeZone),
        end_time: this.getUTCFromTimezone(this.date[1], electionTimeZone),
        electorates: electoratesList,
        questions: questions,
      };
      axios({
        method: "post",
        url: "/api/elections",
        headers: { "Content-Type": "application/json" },
        data: data,
      })
        .then((res) => {
          if (this.publishElection) {
            axios({
              method: "post",
              url: "/api/elections/" + res.data.id + "/publish",
              headers: { "Content-Type": "application/json" },
            })
              .then(() => {
                console.log("Election data");
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  this.errorTitle = "Bad election state";
                  this.errorDescription =
                    "The election was not in a draft state.";
                } else {
                  this.errorTitle = "Unknown Error";
                  this.errorDescription =
                    "Something went wrong. Please try again. If the issue persists, contact an administrator.";
                }
                this.hasError = true;
              });
          }
          alert("Successfully added election");
          window.location.reload(true);
        })
        .catch((err) => {
          this.errorTitle = "Unknown Error";
          this.errorDescription =
            "Something went wrong. Please try again. If the issue persists, contact an administrator.";
          this.hasError = true;
        });
    },
    loadTimeZoneList() {
      var timeZoneList = [];
      var timeZones = moment.tz.names();
      timeZones.forEach((timeZone) => {
        timeZoneList.push({
          textContent: `${timeZone} (GMT${moment.tz(timeZone).format("Z")})`,
          value: timeZone,
        });
      });
      return timeZoneList;
    },
    getUTCFromTimezone(date, electionTimeZone) {
      var oldDate = moment.tz(
        moment(date).format("YYYY-MM-DDTHH:mm:ss"),
        moment.ISO_8601,
        electionTimeZone
      );
      return oldDate.format();
    },
  },
});
</script>
